import axios from 'axios';

const STAG_URL_HEROKU = 'https://webplug-backend.onrender.com/';
const LOCALHOST = 'http://127.0.0.1:8000/';
const RENDER_URL = 'https://webplug-backend.onrender.com/';

const defaultAxiosOptions = {
    baseURL: RENDER_URL
};

export default fetch = axios.create(defaultAxiosOptions);