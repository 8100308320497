import React from 'react';
import {Row, Col} from 'antd';
import styled from 'styled-components';

const Button = styled.button`
  height: 42px;
  width: 120px;
  background-color: #00b7c2;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 14px;
  border-radius: 0.25rem;
  border-color: #00b7c2;
  line-height: 1.5;
`;

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled(FlexCol)`
  padding: 0rem 3rem 0rem 3rem;
  align-items: center;
  justify-content: space-between;
`;

const redirect_url = () => {
    window.location = 'https://webflow.com/oauth/authorize/?client_id=2be3bb7fe368b7b55dc013a9119acb1eea84f94038063a626c6d32d1ab68efae&response_type=code';
};

const AddProject = () => {
    return (
        <Container>
            <div style={{paddingTop: '40px'}}>
                <Row>
                    <Col
                        span={19}
                        style={{paddingTop: '10px'}}
                    >
                        <h6
                            style={{
                                fontWeight: '400',
                                fontSize: '16px',
                                color: '#32325D'
                            }}
                        >
                            Can't find your site ? Add it now
                        </h6>
                    </Col>
                    <Col span={5}>
                        <Button
                            style={{marginLeft: '5px'}}
                            onClick={() => redirect_url()}
                        >
                            Add Project
                        </Button>
                    </Col>
                </Row>
            </div>
        </Container>
    );
};

export default AddProject;