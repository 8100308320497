import React, {useState} from 'react';
import {Row, Col, Layout, Input} from 'antd';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

const {Header} = Layout;
const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled(FlexCol)`
  padding: 3rem;
  align-items: center;
  justify-content: space-between;
`;

const Titlehead = {
    fontWeight: '800',
    fontSize: '20px',
    color: '#32325D'
};

const heading = {
    fontSize: '30px',
    color: '#525f7f'
};

const ad = {
    fontSize: '50px',
    color: '#525f7f'
};

const adContent = {
    fontSize: '35px',
    color: '#525f7f'
};

const OutlineButton = styled.button`
  height: 70px;
  width: 200px;
  background-color: #FFFFFF;
  color: #00b7c2;
  border-color: #00b7c2;
  border-width: 8px;
  font-weight: 600;
  font-size: 14px;
  border-radius: 0.25rem;
  line-height: 1.5;
  -webkit-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
`;

function Home() {
    const [username, setUsername] = useState('harshvardhhan');
    const forked = `${'https://github.com/'}${username}${'/airtable-website'}`;
    const edited = `${'https://github.com/'}${username}${'/airtable-website/edit/master/src/configuration.js'}`;
    return (
        <div className='App'>
            <HeaderComponent/>
            <Container>
                <h1 style={heading}>
                    Make your Airtable go live with your custom domain for free 😃and forever.
                </h1>
                <div style={{width: '80%'}}>
                    <Row style={{marginTop: '50px'}}>
                        <Col span={2}>
                            <h1 style={heading}>1.</h1>
                        </Col>
                        <Col span={22}>
                            <h1 style={heading}>
                                Sign Up for
                                <a
                                    style={{color: '#00b7c2'}}
                                    onClick={() => window.open('https://github.com/join')}
                                >
                                    {' Github '}
                                </a>
                            </h1>
                        </Col>
                    </Row>
                    <Row style={{marginTop: '30px'}}>
                        <Col span={2}>
                            <h1 style={heading}>2.</h1>
                        </Col>
                        <Col span={22}>
                            <h1 style={heading}>
                                Sign Up for
                                <a
                                    style={{color: '#00b7c2'}}
                                    onClick={() => window.open('https://dashboard.render.com/register?next=/')}
                                >
                                    {' Render '}
                                </a>
                                Via Github
                            </h1>
                            <img
                                style={{
                                    width: '100%',
                                    borderImageWidth: '2px',
                                    borderColor: '#00000'
                                }}
                                src={require('./Images/render.png')}
                            />
                        </Col>
                    </Row>
                    <Row style={{marginTop: '30px'}}>
                        <Col span={2}>
                            <h1 style={heading}>3.</h1>
                        </Col>
                        <Col span={22}>
                            <h1 style={heading}>
                                Open
                                <a
                                    style={{color: '#00b7c2'}}
                                    onClick={() => window.open('https://github.com/TrakBit/Airtable-website')}
                                >
                                    {' Airtable Template '}
                                </a>
                                on Github and Fork it.
                            </h1>
                            <img
                                style={{
                                    width: '100%',
                                    borderImageWidth: '2px',
                                    borderColor: '#00000'
                                }}
                                src={require('./Images/fork.png')}
                            />
                        </Col>
                    </Row>
                    <Row style={{marginTop: '30px'}}>
                        <Col span={2}>
                            <h1 style={heading}>4.</h1>
                        </Col>
                        <Col span={22}>
                            <h1 style={heading}>
                                Get your
                                <a
                                    style={{color: '#00b7c2'}}
                                >{' GITHUB_USERNAME '}</a>
                                from here (like harshvardhhan in my case) and Enter it in the field below
                                <div style={{marginTop: '30px'}}>
                                    <Input
                                        addonBefore={'Github Username'}
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                        placeholder='Github Username'
                                        size='large'
                                    />
                                </div>
                            </h1>
                            <img
                                style={{
                                    width: '100%',
                                    borderImageWidth: '2px',
                                    borderColor: '#00000'
                                }}
                                src={require('./Images/username.png')}
                            />
                        </Col>
                    </Row>
                    <Row style={{marginTop: '30px'}}>
                        <Col span={2}>
                            <h1 style={heading}>5.</h1>
                        </Col>
                        <Col span={22}>
                            <h1 style={heading}>
                                open
                                <a
                                    style={{color: '#00b7c2'}}
                                    onClick={() => window.open(forked)}
                                >
                                    {' '}{forked}{' '}
                                </a>
                                and click on
                                <a
                                    style={{color: '#00b7c2'}}
                                >
                                    {' Deploy To Render '}
                                </a>
                                button
                            </h1>
                            <img
                                style={{
                                    width: '100%',
                                    borderImageWidth: '2px',
                                    borderColor: '#00000'
                                }}
                                src={require('./Images/deploy.png')}
                            />
                        </Col>
                    </Row>
                    <Row style={{marginTop: '30px'}}>
                        <Col span={2}>
                            <h1 style={heading}>6.</h1>
                        </Col>
                        <Col span={22}>
                            <h1 style={heading}>
                                Click on Approve and wait for it to create the site
                            </h1>
                            <img
                                style={{
                                    width: '100%',
                                    borderImageWidth: '2px',
                                    borderColor: '#00000'
                                }}
                                src={require('./Images/approve.png')}
                            />
                        </Col>
                    </Row>
                    <Row style={{marginTop: '30px'}}>
                        <Col span={2}>
                            <h1 style={heading}>7.</h1>
                        </Col>
                        <Col span={22}>
                            <h1 style={heading}>
                                Once site created click on Service
                            </h1>
                            <img
                                style={{
                                    width: '100%',
                                    borderImageWidth: '2px',
                                    borderColor: '#00000'
                                }}
                                src={require('./Images/service.png')}
                            />
                        </Col>
                    </Row>
                    <Row style={{marginTop: '30px'}}>
                        <Col span={2}>
                            <h1 style={heading}>8.</h1>
                        </Col>
                        <Col span={22}>
                            <h1 style={heading}>
                                Open Application
                            </h1>
                            <img
                                style={{
                                    width: '100%',
                                    borderImageWidth: '2px',
                                    borderColor: '#00000'
                                }}
                                src={require('./Images/application.png')}
                            />
                        </Col>
                    </Row>
                    <Row style={{marginTop: '30px'}}>
                        <Col span={2}>
                            <h1 style={heading}>9.</h1>
                        </Col>
                        <Col span={22}>
                            <h1 style={heading}>
                                Now you can open your live URL 😃
                            </h1>
                            <img
                                style={{
                                    width: '100%',
                                    borderImageWidth: '2px',
                                    borderColor: '#00000'
                                }}
                                src={require('./Images/url.png')}
                            />
                        </Col>
                    </Row>
                    <Row style={{marginTop: '30px'}}>
                        <Col span={2}>
                            <h1 style={heading}>10.</h1>
                        </Col>
                        <Col span={22}>
                            <h1 style={heading}>
                                Now Edit the project on this URL
                                <a
                                    style={{color: '#00b7c2'}}
                                    onClick={() => window.open(edited)}
                                >
                                    {' '}{edited}{' '}
                                </a>
                            </h1>
                            <img
                                style={{
                                    width: '100%',
                                    borderImageWidth: '2px',
                                    borderColor: '#00000'
                                }}
                                src={require('./Images/edit.png')}
                            />
                        </Col>
                    </Row>
                    <Row style={{marginTop: '30px'}}>
                        <Col span={2}>
                            <h1 style={heading}>11.</h1>
                        </Col>
                        <Col span={22}>
                            <h1 style={heading}>
                                Now scroll down and click on commit changes, your website data will be updated.
                                <a
                                    style={{color: '#00b7c2'}}
                                    onClick={() => window.open(edited)}
                                >
                                    {' '}{edited}{' '}
                                </a>
                            </h1>
                            <img
                                style={{
                                    width: '100%',
                                    borderImageWidth: '2px',
                                    borderColor: '#00000'
                                }}
                                src={require('./Images/commit.png')}
                            />
                        </Col>
                    </Row>
                    <Row style={{marginTop: '30px'}}>
                        <Col span={2}>
                            <h1 style={heading}>12.</h1>
                        </Col>
                        <Col span={22}>
                            <h1 style={heading}>
                                Now you can follow few simple steps to connect site with your custom domain by clicking
                                <a
                                    onClick={() => window.open('https://render.com/docs/custom-domains')}
                                    style={{color: '#00b7c2'}}
                                >
                                    {'  here '}
                                </a>
                                and you are all done 🥳 Great Job
                            </h1>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    );
}

const HeaderComponent = () => {
    return (
        <Header style={{background: '#FFFFFF'}}>
            <Row>
                <Col span={1}>
                    <Icon/>
                </Col>
                <Col span={18}>
                    <Link to={'/dashboard'}><h1 style={Titlehead}>WebPlug</h1></Link>
                </Col>
                <Col span={2}/>
                <Col
                    span={2}
                    style={{marginLeft: '10px'}}
                />
            </Row>
        </Header>
    );
};

function Icon() {
    return (
        <div style={{marginTop: '10px', width: '10px'}}>
            <svg
                xmlns='http://www.w3.org/2000/svg'
                width='30pt'
                height='30pt'
                viewBox='0 0 512 512'
            >
                <path d='M255.055 0C113.988.512 0 114.836 0 256c0 141.48 114.496 256 256 256 11.047 0 20-8.953 20-20s-8.953-20-20-20c-119.379 0-216-96.61-216-216 0-119.02 96.094-215.566 215.2-216C373.29 39.512 472 137.395 472 257.832c0 74.715-60.785 135.5-135.504 135.5h-1.832c-26.3 0-48.613-17.398-56.035-41.289 43.535-10.254 76.035-49.422 76.035-96.043v-78.668c0-11.047-8.953-20-20-20h-19.332V98.668c0-11.047-8.953-20-20-20s-20 8.953-20 20v58.664h-38.668V98.668c0-11.047-8.953-20-20-20-11.043 0-20 8.953-20 20v58.664h-19.332c-11.047 0-20 8.953-20 20V256c0 48.156 34.68 88.355 80.375 96.96 8.602 45.692 48.805 80.372 96.957 80.372h1.832c96.774 0 175.504-78.73 175.504-175.5C512 116.738 396.883-.445 255.055 0zm-57.723 256v-58.668h117.332V256c0 32.348-26.316 58.668-58.664 58.668-32.352 0-58.668-26.32-58.668-58.668zm0 0'/>
            </svg>
        </div>
    );
}

export default Home;