import React from 'react';
import Dialog from 'react-modal';
import styled from 'styled-components';
import {Row, Col, Card, Radio} from 'antd';

const pricingStyles = {
    content: {
        height: '400px',
        width: '800px',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '0.25rem',
        boxShadow: '0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)'
    }
};

const FilterType = styled.div`
  height: 22px;
  width: 80px;
  background-color: #00b7c2;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 12px;
  border-radius: 0.25rem;
  line-height: 1.9;
`;

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;

const ModalContainer = styled(FlexCol)`
  padding: 0rem;
  align-items: center;
  justify-content: space-between;
`;

const Button = styled.button`
  height: 42px;
  width: 120px;
  background-color: #00b7c2;
  border-color: #00b7c2;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 14px;
  border-radius: 0.25rem;
  line-height: 1.5;
`;

const head = {
    fontWeight: '500',
    fontSize: '28px',
    color: '#32325D'
};

const FilterName = {
    fontWeight: '500',
    fontSize: '20px',
    color: '#32325D'
};

const PricingModal = ({
    pricingModal,
    pricingModalVisible,
    plans, selectPlan,
    selectedPlan,
    buyPlanAction,
    charge
}) => {
    return (
        <Dialog
            ariaHideApp={false}
            isOpen={pricingModal}
            style={pricingStyles}
            onRequestClose={() => pricingModalVisible(false)}
        >
            <ModalContainer>
                <h4 style={head}>{'Plans'}</h4>
                <Radio.Group
                    onChange={selectPlan}
                    value={selectedPlan}
                >
                    <ModalContainer>
                        <Row>
                            {plans.map((value, i) => {
                                return (
                                    <Col
                                        span={12}
                                        key={i}
                                    >
                                        <ModalContainer>
                                            <Card style={{height: '150px', width: '300px'}}>
                                                <Row>
                                                    <Col span={1}>
                                                        <Radio
                                                            style={{marginTop: '5px'}}
                                                            value={value.id}
                                                        />
                                                    </Col>
                                                    <Col span={2}/>
                                                    <Col span={21}>
                                                        <h5 style={FilterName}>
                                                            {value.name}
                                                        </h5>
                                                        ${value.monthly} per month
                                                        <Discount interval={value.interval}/>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </ModalContainer>
                                    </Col>
                                );
                            })}
                        </Row>
                    </ModalContainer>
                </Radio.Group>
                <br/>
                <h5 style={FilterName}>What will you be charged now : {charge}</h5>
                <br/>
                <Button onClick={buyPlanAction}> Buy </Button>
            </ModalContainer>
        </Dialog>
    );
};

const Discount = ({interval}) => {
    if (interval === 'year') {
        return (
            <FilterType> <b style={{marginLeft: '8px'}}>SAVE 20%</b></FilterType>
        );
    } else {
        return <div/>;
    }
};

export default PricingModal;