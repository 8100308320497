import React from 'react';
import styled from 'styled-components';
import {PageHeader, Col, Row, Layout} from 'antd';
import {Link} from 'react-router-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

const {Header} = Layout;

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled(FlexCol)`
  padding: 1rem;
  align-items: center;
  padding: 1rem;
  justify-content: space-between;
`;

const ContentContainer = styled(FlexCol)`
  padding: 0rem 12rem 0rem 12rem;
  align-items: left;
  justify-content: space-between;
`;

function Tou() {
    return (
        <div
            className='App'
            style={{backgroundColor: '#FFFFFF'}}
        >
            <Header style={{height: 10, background: '#00b7c2'}}/>
            <PageHeader
                style={{backgroundColor: '#f4f9f4'}}
                className='site-page-header'
                title={
                    <>
                        <div id='parent_div_1'>
                            <Icon/>
                        </div>
                        <div id='parent_div_2'>
                            <Link to={'/'}>
                                <a style={{color: '#000000'}}>
                                    WebPlug
                                </a>
                            </Link>
                        </div>
                    </>
                }
            />
            <Row>
                <Col span={24}>
                    <Container>
                        <h1 style={{fontSize: '4vw'}}>
                            Terms of Use
                        </h1>
                    </Container>
                    <ContentContainer>
                        <h1>Terms of Service</h1>
                        <p>These Terms and Conditions ("Terms", "Terms and Conditions") govern your
                            relationship with https://www.webplug.xyz/ website (the "Service") operated by
                            TrakBit TechSolutions LLP ("us", "we", or "our"). Please read these Terms and
                            Conditions carefully before using the Service. Your access to and use of the
                            Service is conditioned on your acceptance of and compliance with these Terms.
                            These Terms apply to all visitors, users and others who access or use the Service.
                            By accessing or using the Service you agree to be bound by these Terms.
                            If you disagree with any part of the terms then you may not access the Service.</p>
                    </ContentContainer>

                    <ContentContainer>
                        <h1>Subscriptions</h1>
                        <p>Some parts of the Service are billed on a subscription basis ("Subscription(s)").
                            You will be billed in advance on a recurring and periodic basis ("Billing Cycle").
                            Billing cycles are set either on a monthly or annual basis, depending on the type
                            of subscription plan you select when purchasing a Subscription. At the end of each
                            Billing Cycle, your Subscription will automatically renew under the exact same
                            conditions unless you cancel it or TrakBit TechSolutions LLP cancels it.
                            You may cancel your Subscription renewal at any time either through your online
                            account billing page or by contacting TrakBit TechSolutions LLP customer support team.
                            A valid payment method, including credit card, is required to process the
                            payment for your Subscription. You shall provide TrakBit TechSolutions LLP with
                            valid payment method information. By submitting such payment information, you
                            automatically authorize TrakBit TechSolutions LLP to charge all Subscription fees
                            incurred through your account to any such payment instruments. Should automatic
                            billing fail to occur for any reason, TrakBit TechSolutions LLP will issue an
                            electronic invoice indicating that you must proceed manually, within a certain
                            deadline date, with the full payment corresponding to the billing period as
                            indicated on the invoice.</p>
                    </ContentContainer>

                    <ContentContainer>
                        <h1>Fee Changes</h1>
                        <p>TrakBit TechSolutions LLP, in its sole discretion and at any time, may
                            modify the Subscription fees for the Subscriptions. Any Subscription fee
                            change will become effective at the end of the then-current Billing Cycle.
                            TrakBit TechSolutions LLP will provide you with a reasonable prior notice of any
                            change in Subscription fees to give you an opportunity to terminate your
                            Subscription before such change becomes effective. Your continued use of the
                            Service after the Subscription fee change comes into effect constitutes your
                            agreement to pay the modified Subscription fee amount.</p>
                    </ContentContainer>

                    <ContentContainer>
                        <h1>Refunds</h1>
                        <p>Except when required by law, paid Subscription fees are non-refundable.</p>
                    </ContentContainer>

                    <ContentContainer>
                        <h1>Accounts</h1>
                        <p>When you create an account with us, you must provide us information that
                            is accurate, complete, and current at all times. Failure to do so constitutes
                            a breach of the Terms, which may result in immediate termination of your
                            account on our Service. You are responsible for safeguarding the password
                            that you use to access the Service and for any activities or actions under
                            your password, whether your password is with our Service or a third-party service.
                            You agree not to disclose your password to any third party. You must notify us
                            immediately upon becoming aware of any breach of security or unauthorized use
                            of your account. You may not use as a username the name of another person or
                            entity or that is not lawfully available for use, a name or trade mark that is
                            subject to any rights of another person or entity other than you without
                            appropriate authorization, or a name that is otherwise offensive, vulgar or obscene.</p>
                    </ContentContainer>

                    <ContentContainer>
                        <h1>Intellectual Property</h1>
                        <p>The Service and its original content, features and functionality are and will
                            remain the exclusive property of TrakBit TechSolutions LLP and its licensors.
                            The Service is protected by copyright, trademark, and other laws of both the
                            India and foreign countries. Our trademarks and trade dress may not be
                            used in connection with any product or service without the prior written consent
                            of TrakBit TechSolutions LLP.</p>
                    </ContentContainer>

                    <ContentContainer>
                        <h1>Links To Other Web Sites</h1>
                        <p>Our Service may contain links to third-party web sites or services
                            that are not owned or controlled by TrakBit TechSolutions LLP. TrakBit TechSolutions LLP
                            has no control over, and assumes no responsibility for, the content, privacy policies,
                            or practices of any third party web sites or services. You further acknowledge and
                            agree that TrakBit TechSolutions LLP shall not be responsible or liable, directly or
                            indirectly, for any damage or loss caused or alleged to be caused by or in connection
                            with use of or reliance on any such content, goods or services available on or
                            through any such web sites or services. We strongly advise you to read the terms and
                            conditions and privacy policies of any third-party web sites or services that
                            you visit.</p>
                    </ContentContainer>

                    <ContentContainer>
                        <h1>Termination</h1>
                        <p>We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms. Upon termination, your right to use the Service will immediately cease. If you wish to terminate your account, you may simply discontinue using the Service.</p>
                    </ContentContainer>

                    <ContentContainer>
                        <h1>Limitation Of Liability</h1>
                        <p>In no event shall TrakBit TechSolutions LLP, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.</p>
                    </ContentContainer>

                    <ContentContainer>
                        <h1>Disclaimer</h1>
                        <p>Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE" basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance. TrakBit TechSolutions LLP its subsidiaries, affiliates, and its licensors do not warrant that a) the Service will function uninterrupted, secure or available at any particular time or location; b) any errors or defects will be corrected; c) the Service is free of viruses or other harmful components; or d) the results of using the Service will meet your requirements.</p>
                    </ContentContainer>

                    <ContentContainer>
                        <h1>Governing Law</h1>
                        <p>These Terms shall be governed and construed in accordance with the laws of India, without regard to its conflict of law provisions. Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have between us regarding the Service.</p>
                    </ContentContainer>

                    <ContentContainer>
                        <h1>Changes</h1>
                        <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 30 days notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion. By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Service.</p>
                    </ContentContainer>

                </Col>
            </Row>

            <Header style={{marginTop: 40, height: 10, background: '#00b7c2'}}/>
        </div>
    );
}

function Icon() {
    return (
        <div style={{width: '10px'}}>
            <svg
                xmlns='http://www.w3.org/2000/svg'
                width='30pt'
                height='30pt'
                viewBox='0 0 512 512'
            >
                <path d='M255.055 0C113.988.512 0 114.836 0 256c0 141.48 114.496 256 256 256 11.047 0 20-8.953 20-20s-8.953-20-20-20c-119.379 0-216-96.61-216-216 0-119.02 96.094-215.566 215.2-216C373.29 39.512 472 137.395 472 257.832c0 74.715-60.785 135.5-135.504 135.5h-1.832c-26.3 0-48.613-17.398-56.035-41.289 43.535-10.254 76.035-49.422 76.035-96.043v-78.668c0-11.047-8.953-20-20-20h-19.332V98.668c0-11.047-8.953-20-20-20s-20 8.953-20 20v58.664h-38.668V98.668c0-11.047-8.953-20-20-20-11.043 0-20 8.953-20 20v58.664h-19.332c-11.047 0-20 8.953-20 20V256c0 48.156 34.68 88.355 80.375 96.96 8.602 45.692 48.805 80.372 96.957 80.372h1.832c96.774 0 175.504-78.73 175.504-175.5C512 116.738 396.883-.445 255.055 0zm-57.723 256v-58.668h117.332V256c0 32.348-26.316 58.668-58.664 58.668-32.352 0-58.668-26.32-58.668-58.668zm0 0'/>
            </svg>
        </div>
    );
}

export default Tou;