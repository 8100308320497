import React, {useState} from 'react';
import {Row, Col, Select, Card, Button} from 'antd';
import styled from 'styled-components';
import {
    CopyOutlined,
    InfoCircleTwoTone
} from '@ant-design/icons';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {getTemplate} from '../../Api/Api';
import ReactDataSheet from 'react-datasheet';
import 'react-datasheet/lib/react-datasheet.css';
import Lottie from 'react-lottie';
import * as Tick from './tick.json';
import * as Cross from './cross.json';

const {Option} = Select;

const heading = {
    fontSize: '20px',
    color: '#525f7f'
};

export const Wrapper = styled.div`
  width: "100%";
  box-sizing: border-box;
  & > span {
    &:focus {
      outline: none;
    }
  }
`;

export const SomeWrapper = styled.table`
  margin-top: 20px;
  border-collapse: collapse;
  border-spacing: 2px;
  border-color: grey;
  &:focus {
    outline: none;
  }
`;

export const TableRow = styled.tr`
  &:focus {
    outline: none;
  }
`;

export const TableCell = styled.td`
  &.data-grid-container .data-grid .cell.read-only {
    background: black !important;
  }
  width: 150px;
  padding: 0;
  & > span {
    display: block;
  }
  &.selected {
    & > span {
      display: block;
    }
  }

  & > input {
    width: 100px;
    outline: none;
    border: none;
  }
`;

const SaveButton = styled.div`
  height: 40px;
  width: 100%;
  background-color: #00b7c2;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 15px;
  border-radius: 0.25rem;
  line-height: 2.7;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const script = 'Airtable-Id';

const tickOptions = {
    loop: false,
    autoplay: true,
    animationData: Tick.default,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const crossOptions = {
    loop: false,
    autoplay: true,
    animationData: Cross.default,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const GenerateTemplate = ({
    location,
    collections,
    selectedCollection,
    selectCollection,
    selectedCollectionName
}) => {
    const [template, setTemplate] = useState([]);
    const [tickMotionStopped, setTickMotion] = useState(true);
    const [referenceField, setReferenceField] = useState(-1);
    const [airtableField, setAirtableField] = useState(-1);

    const getTemplateAction = async () => {
        if (selectedCollection.length > 1) {
            const token = localStorage.getItem('token');
            const templateData = await getTemplate(
                selectedCollection,
                token,
                location.state.plug_id);
            setTemplate(templateData.data.template);
            setReferenceField(templateData.data.reference_field);
            setAirtableField(templateData.data.airtable_field);

            setTickMotion(false);
        }
    };

    return (
        <div>
            <Row>
                <Col span={21}>
                    <h1 style={{fontSize: '22px'}}>Generate Template</h1>
                </Col>
            </Row>
            <hr style={{color: '#ececec', width: '100%'}}/>
            <Row style={{marginTop: '50px'}}>
                <Col span={1}>
                    <h1 style={heading}>1.</h1>
                </Col>
                <Col span={21}>
                    <h1 style={heading}>
                        Which collection to sync with Airtable ?
                    </h1>
                    <h4 style={{fontSize: '16px', color: '#525f7f'}}>Collection List</h4>
                    <Select
                        style={{fontSize: '16px', width: '100%'}}
                        defaultValue={selectedCollection}
                        value={selectedCollection}
                        onChange={selectCollection}
                    >
                        {collections.map((value, i) => {
                            return (
                                <Option
                                    key={i}
                                    value={value.id}
                                >{value.name}</Option>
                            );
                        })}
                    </Select>
                    <Card
                        style={{
                            borderRadius: '2px',
                            borderColor: '#ccc',
                            marginTop: '5px'
                        }}
                    >
                        <Row>
                            <Col span={3}>
                                <InfoCircleTwoTone style={{fontSize: '30px', fontWeight: '500'}}/>
                            </Col>
                            <Col
                                span={21}
                            >
                                <h4 style={{color: '#525f7f'}}>
                                    <a
                                        style={{fontSize: '17px', color: '#525f7f'}}
                                    >
                                        NOTE: Reference and Multi-Reference Field Will not be generated in the template, you'll have to update them manually
                                    </a>
                                </h4>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={2}>
                    <ListCheckComponent
                        listCheck={referenceField}
                        tickMotionStopped={tickMotionStopped}
                    />
                </Col>
            </Row>

            <Row style={{marginTop: '50px'}}>
                <Col span={1}>
                    <h1 style={heading}>2.</h1>
                </Col>
                <Col span={21}>
                    <h1 style={heading}>
                        Add Field Airtable-Id
                    </h1>
                    <h4 style={{fontSize: '16px', color: '#525f7f'}}>
                        Add collection field Airtable-Id (Plain Text) to Weblfow CMS collection {selectedCollectionName}
                    </h4>
                    <img
                        style={{
                            width: '100%',
                            borderImageWidth: '2px',
                            borderColor: '#00000'
                        }}
                        src={require('./airtable-id.png')}
                    />
                    <Card
                        style={{
                            borderRadius: '2px',
                            width: '100%',
                            marginTop: '4%',
                            borderColor: '#ccc',
                            backgroundColor: '#f7f7f7'
                        }}
                    >
                        <Row>
                            <Col
                                span={21}
                                style={{fontSize: '14px', fontWeight: '500'}}
                            >{script}</Col>
                            <Col span={2}/>
                            <Col span={1}>
                                <CopyToClipboard text={script}>
                                    <Button
                                        type='primary'
                                        icon={<CopyOutlined/>}
                                        size={'small'}
                                    />
                                </CopyToClipboard>
                            </Col>
                        </Row>
                    </Card>
                    <Card
                        style={{
                            borderRadius: '2px',
                            borderColor: '#ccc',
                            marginTop: '5px'
                        }}
                    >
                        <Row>
                            <Col span={3}>
                                <InfoCircleTwoTone style={{fontSize: '30px', fontWeight: '500'}}/>
                            </Col>
                            <Col
                                span={21}
                            >
                                <h4 style={{color: '#525f7f'}}>
                                    <a
                                        style={{fontSize: '17px', color: '#525f7f'}}
                                    >
                                        NOTE: Do NOT add any value in Airtable-Id column. The value is auto generated.
                                    </a>
                                </h4>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={2}>
                    <ListCheckComponent
                        listCheck={airtableField}
                        tickMotionStopped={tickMotionStopped}
                    />
                </Col>
            </Row>

            <Row style={{marginTop: '50px'}}>
                <Col span={1}/>
                <Col span={21}>
                    <a onClick={getTemplateAction}>
                        <SaveButton>
                            {'GENERATE TEMPLATE'}
                        </SaveButton>
                    </a>
                </Col>
            </Row>

            <Row style={{marginTop: '10px'}}>
                <Col span={1}/>
                <SpreadSheet template={template}/>
            </Row>
        </div>
    );
};

const SpreadSheet = ({template}) => {
    if (template.length > 0) {
        return (
            <Col span={21}>
                <Wrapper style={{width: 'inherit', overflow: 'scroll'}}>
                    <ReactDataSheet
                        data={template}
                        valueRenderer={(cell) => cell.value}
                        cellRenderer={TableCell}
                        sheetRenderer={SomeWrapper}
                    />
                </Wrapper>

                <div style={{marginTop: '10px'}}>
                    <h4 style={{fontSize: '16px', color: '#525f7f'}}>
                        Create a table in Airtable with same column names
                    </h4>
                </div>

                <Card
                    style={{
                        borderRadius: '2px',
                        borderColor: '#ccc',
                        marginTop: '5px'
                    }}
                >
                    <Row>
                        <Col span={3}>
                            <InfoCircleTwoTone style={{fontSize: '30px', fontWeight: '500'}}/>
                        </Col>
                        <Col
                            span={21}
                        >
                            <h4 style={{color: '#525f7f'}}>
                                <a
                                    style={{fontSize: '17px', color: '#525f7f'}}
                                >
                                    NOTE: Do NOT enter any value in webflow-id column since its auto generated.
                                </a>
                            </h4>
                        </Col>
                    </Row>
                </Card>

                <Card
                    style={{
                        borderRadius: '2px',
                        borderColor: '#ccc',
                        marginTop: '5px'
                    }}
                >
                    <Row>
                        <Col span={3}>
                            <InfoCircleTwoTone style={{fontSize: '30px', fontWeight: '500'}}/>
                        </Col>
                        <Col
                            span={21}
                        >
                            <h4 style={{color: '#525f7f'}}>
                                <a
                                    style={{fontSize: '17px', color: '#525f7f'}}
                                >
                                    NOTE: Enter value in slug column only when syncing first time. After that its overriden by
                                    auto generated value.
                                </a>
                            </h4>
                        </Col>
                    </Row>
                </Card>

                <Card
                    style={{
                        borderRadius: '2px',
                        borderColor: '#ccc',
                        marginTop: '5px'
                    }}
                >
                    <Row>
                        <Col span={3}>
                            <InfoCircleTwoTone style={{fontSize: '30px', fontWeight: '500'}}/>
                        </Col>
                        <Col
                            span={21}
                        >
                            <h4 style={{color: '#525f7f'}}>
                                <a
                                    style={{fontSize: '17px', color: '#525f7f'}}
                                >
                                    NOTE: All field types should be only of Single Line Text in Airtable
                                </a>
                            </h4>
                        </Col>
                    </Row>
                </Card>
            </Col>

        );
    } else {
        return (
            <Col span={21}>No Data</Col>
        );
    }
};

const ListCheckComponent = ({listCheck, tickMotionStopped}) => {
    if (listCheck === 1) {
        return (
            <Lottie
                options={tickOptions}
                isPaused={tickMotionStopped}
                height={60}
                width={60}
            />
        );
    } else if (listCheck === 0) {
        return (
            <Lottie
                options={crossOptions}
                isPaused={tickMotionStopped}
                height={60}
                width={60}
            />
        );
    } else {
        return <div/>;
    }
};

export default GenerateTemplate;