import React, {useState, useEffect} from 'react';
import {Layout, Menu, Input} from 'antd';
import styled from 'styled-components';
import {saveName} from '../../Api/Api';

import {
    CopyOutlined,
    SyncOutlined
} from '@ant-design/icons';

const {Sider} = Layout;

const FilterType = styled.div`
  height: 22px;
  width: 140px;
  background-color: #00b7c2;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 12px;
  border-radius: 0.25rem;
  line-height: 1.9;
`;

const PlugAirtableSidebar = ({setSelectedKeys, selectedKeys, location}) => {
    const [plugName, setPlugName] = useState('');

    const selectItem = (e) => {
        setSelectedKeys([e.key]);
    };

    const saveNameAction = () => {
        const token = localStorage.getItem('token');
        saveName(location.state.plug_id, plugName, token);
    };

    useEffect(() => {
        setPlugName(location.state.plug);
    }, []);

    return (
        <Sider style={{backgroundColor: '#30475e'}}>

            <div style={{marginLeft: '20px', marginTop: '10px'}}>
                <a style={{color: '#FFFFFF', fontWeight: '300'}}>
                    Name
                </a>
                <br/>
                <a style={{color: '#FFFFFF', fontWeight: '500'}}>
                    <Input
                        style={{
                            width: '150px',
                            color: '#FFFFFF',
                            backgroundColor: '#30475e'
                        }}
                        value={plugName}
                        onChange={(e) => setPlugName(e.target.value)}
                        onBlur={saveNameAction}
                    />
                </a>
            </div>

            <div style={{marginLeft: '20px', marginTop: '15px'}}>
                <a style={{color: '#FFFFFF', fontWeight: '400'}}>
                    {location.state.site}
                </a>
                <FilterType>
                    <a style={{marginLeft: '12px', color: '#FFFFFF'}}>
                        {location.state.plug_name}
                    </a>
                </FilterType>
            </div>

            <div style={{marginTop: '15px'}}>
                <Menu
                    defaultOpenKeys={['sub1']}
                    selectedKeys={selectedKeys}
                    style={{minHeight: '71vh', backgroundColor: '#30475e'}}
                    onClick={selectItem}
                    theme='dark'
                >
                    <Menu.Item key='1'>
                        <CopyOutlined/>
                        Generate Template
                    </Menu.Item>
                    <Menu.Item key='2'>
                        <SyncOutlined/>
                        Sync Data
                    </Menu.Item>
                </Menu>
            </div>

        </Sider>
    );
};

export default PlugAirtableSidebar;