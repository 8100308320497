import React, {useState} from 'react';
import {Select, Row, Col} from 'antd';
import {savePlug} from '../../Api/Api';
import styled from 'styled-components';
import Lottie from 'react-lottie';
import * as animationData from './loading.json';
import * as Tick from './tick.json';

const {Option} = Select;

const SaveButton = styled.div`
  height: 40px;
  width: 100%;
  background-color: #00b7c2;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 15px;
  border-radius: 0.25rem;
  line-height: 2.7;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const heading = {
    fontSize: '20px',
    color: '#525f7f'
};

const PlugSettings = ({
    selectedCollection,
    selectCollection,
    collections,
    selectedField,
    fieldSelector,
    selectField,
    selectMultiple,
    selectedMultiple,
    selectedAnyAll,
    selectAnyAll,
    fields,
    location
}) => {
    const [loading, setLoading] = useState(false);
    const [tickMotionStopped, setTickMotion] = useState(true);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData.default,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const tickOptions = {
        loop: false,
        autoplay: true,
        animationData: Tick.default,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const save = async () => {
        if (loading === false) {
            if (selectedField !== '') {
                setLoading(true);
                const savedPlug = await savePlug(
                    location.state.plug_id,
                    selectedCollection,
                    selectedField,
                    selectedMultiple,
                    selectedAnyAll,
                    localStorage.getItem('token'));
                if (savedPlug.status === 200) {
                    setLoading(false);
                    setTickMotion(false);
                }
            }
        }
    };

    const Spinner = ({loading, defaultOptions}) => {
        if (loading === true) {
            return (
                <SaveButton>
                    <Lottie
                        options={defaultOptions}
                        height={30}
                        width={350}
                    />
                </SaveButton>
            );
        } else {
            return (
                <a>
                    <SaveButton
                        onClick={save}
                    >
                        {'SAVE'}
                    </SaveButton>
                </a>
            );
        }
    };

    return (
        <div>
            <Row>
                <Col span={21}>
                    <h1 style={{fontSize: '22px'}}>Configure filter plug</h1>
                </Col>
                <Col span={3}>
                    <TickComponent
                        tickOptions={tickOptions}
                        tickMotionStopped={tickMotionStopped}
                    />
                </Col>
            </Row>
            <hr style={{color: '#ececec', width: '100%'}}/>

            <Row style={{marginTop: '50px'}}>
                <Col span={1}>
                    <h1 style={heading}>1.</h1>
                </Col>
                <Col span={21}>
                    <h1 style={heading}>
                        Which collection to filter ?
                    </h1>
                    <h4 style={{fontSize: '16px', color: '#525f7f'}}>Collection List</h4>
                    <Select
                        style={{fontSize: '16px', width: '100%'}}
                        defaultValue={selectedCollection}
                        value={selectedCollection}
                        onChange={selectCollection}
                    >
                        {collections.map((value, i) => {
                            return (
                                <Option
                                    key={i}
                                    value={value.id}
                                >{value.name}</Option>
                            );
                        })}
                    </Select>
                </Col>
            </Row>

            <Row style={{marginTop: '50px'}}>
                <Col span={1}>
                    <h1 style={heading}>2.</h1>
                </Col>
                <Col span={21}>
                    <h1 style={heading}>
                        On what basis to filter by ?
                    </h1>
                    <h4 style={{fontSize: '16px', color: '#525f7f'}}>Field</h4>
                    <Select
                        style={{fontSize: '16px', width: '100%'}}
                        defaultValue={selectedField}
                        disabled={fieldSelector}
                        value={selectedField}
                        onChange={selectField}
                    >
                        {fields.map((value, i) => {
                            return (
                                <Option
                                    key={i}
                                    value={value.id}
                                >
                                    {value.name}
                                </Option>
                            );
                        })}
                    </Select>
                </Col>
            </Row>

            <Row style={{marginTop: '50px'}}>
                <Col span={1}>
                    <h1 style={heading}>3.</h1>
                </Col>
                <Col span={21}>
                    <h1 style={heading}>
                        Allow multiple selection of fields ?
                    </h1>
                    <h4 style={{fontSize: '16px', color: '#525f7f'}}>
                        Filter collection by multiple criteria</h4>
                    <Select
                        style={{fontSize: '16px', width: '100%'}}
                        defaultValue={selectedMultiple}
                        onChange={selectMultiple}
                        value={selectedMultiple}
                    >
                        <Option value={1}>Yes</Option>
                        <Option value={0}>No</Option>
                    </Select>
                </Col>
            </Row>

            <AnyAll
                selectedMultiple={selectedMultiple}
                selectMultiple={selectMultiple}
                selectedAnyAll={selectedAnyAll}
                selectAnyAll={selectAnyAll}
            />

            <Row style={{marginTop: '50px'}}>
                <Col span={1}/>
                <Col span={21}>
                    <Spinner
                        loading={loading}
                        defaultOptions={defaultOptions}
                    />
                </Col>
            </Row>
        </div>
    );
};

const AnyAll = ({selectedAnyAll, selectedMultiple, selectAnyAll}) => {
    if (selectedMultiple === 1) {
        return (
            <Row style={{marginTop: '50px'}}>
                <Col span={1}>
                    <h1 style={heading}>4.</h1>
                </Col>
                <Col span={21}>
                    <h1 style={heading}>
                        Filter by ALL the criteria or ANY of the criteria?
                    </h1>
                    <h4 style={{fontSize: '16px', color: '#525f7f'}}>All or Any</h4>
                    <Select
                        style={{fontSize: '16px', width: '100%'}}
                        defaultValue={selectedAnyAll}
                        onChange={selectAnyAll}
                        value={selectedAnyAll}
                    >
                        <Option value={1}>All</Option>
                        <Option value={0}>Any</Option>
                    </Select>
                </Col>
            </Row>
        );
    } else {
        return <div/>;
    }
};

const TickComponent = ({tickOptions, tickMotionStopped}) => {
    if (tickMotionStopped === false) {
        return (
            <Lottie
                options={tickOptions}
                isPaused={tickMotionStopped}
                height={60}
                width={60}
            />
        );
    } else {
        return <div/>;
    }
};

export default PlugSettings;