import React from 'react';
import styled from 'styled-components';
import {PageHeader, Col, Row, Layout} from 'antd';
import {Link} from 'react-router-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

const {Header} = Layout;

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled(FlexCol)`
  padding: 1rem;
  align-items: center;
  padding: 1rem;
  justify-content: space-between;
`;

const ContentContainer = styled(FlexCol)`
  padding: 0rem 12rem 0rem 12rem;
  align-items: left;
  justify-content: space-between;
`;

const OutlineButton = styled.button`
  height: 42px;
  width: 120px;
  background-color: #FFFFFF;
  color: #00b7c2;
  border-color: #00b7c2;
  border-width: 4px;
  font-weight: 600;
  font-size: 14px;
  border-radius: 0.25rem;
  line-height: 1.5;
  -webkit-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
`;

function About() {
    return (
        <div
            className='App'
            style={{backgroundColor: '#FFFFFF'}}
        >
            <Header style={{height: 10, background: '#00b7c2'}}/>
            <PageHeader
                style={{backgroundColor: '#f4f9f4'}}
                className='site-page-header'
                title={
                    <>
                        <div id='parent_div_1'>
                            <Icon/>
                        </div>
                        <div id='parent_div_2'>
                            <Link to={'/'}>
                                <a style={{color: '#000000'}}>
                                    WebPlug
                                </a>
                            </Link>
                        </div>
                    </>
                }
            />
            <Row>
                <Col span={24}>
                    <Container>
                        <h1 style={{fontSize: '4vw'}}>
                            About Us
                        </h1>
                    </Container>
                    <ContentContainer>
                        <h1>About Us</h1>
                        <p>We aim to give entrepreneur the ability to build applications without the need to code </p>
                    </ContentContainer>

                    <ContentContainer>
                        <h1>Office Address</h1>
                        <p>D-29, Sector-61, Noida, Uttar Pradesh, India</p>
                    </ContentContainer>

                    <ContentContainer>
                        <OutlineButton>
                            <a
                                style={{color: '#00b7c2'}}
                                href='mailto:harsh.vardhan.611@gmail.com'
                            >Contact Us</a>
                        </OutlineButton>
                    </ContentContainer>

                </Col>
            </Row>
        </div>
    );
}

function Icon() {
    return (
        <div style={{width: '10px'}}>
            <svg
                xmlns='http://www.w3.org/2000/svg'
                width='30pt'
                height='30pt'
                viewBox='0 0 512 512'
            >
                <path d='M255.055 0C113.988.512 0 114.836 0 256c0 141.48 114.496 256 256 256 11.047 0 20-8.953 20-20s-8.953-20-20-20c-119.379 0-216-96.61-216-216 0-119.02 96.094-215.566 215.2-216C373.29 39.512 472 137.395 472 257.832c0 74.715-60.785 135.5-135.504 135.5h-1.832c-26.3 0-48.613-17.398-56.035-41.289 43.535-10.254 76.035-49.422 76.035-96.043v-78.668c0-11.047-8.953-20-20-20h-19.332V98.668c0-11.047-8.953-20-20-20s-20 8.953-20 20v58.664h-38.668V98.668c0-11.047-8.953-20-20-20-11.043 0-20 8.953-20 20v58.664h-19.332c-11.047 0-20 8.953-20 20V256c0 48.156 34.68 88.355 80.375 96.96 8.602 45.692 48.805 80.372 96.957 80.372h1.832c96.774 0 175.504-78.73 175.504-175.5C512 116.738 396.883-.445 255.055 0zm-57.723 256v-58.668h117.332V256c0 32.348-26.316 58.668-58.664 58.668-32.352 0-58.668-26.32-58.668-58.668zm0 0'/>
            </svg>
        </div>
    );
}

export default About;