import React, {useState, useEffect} from 'react';
import {Row, Col, Select, Card, Button, Input} from 'antd';
import {verifyScript, verifyField, saveElement} from '../../Api/Api';
import styled from 'styled-components';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {
    CopyOutlined,
    PlayCircleOutlined
} from '@ant-design/icons';
import Lottie from 'react-lottie';
import * as Tick from './tick.json';
import * as Cross from './cross.json';

const {Option} = Select;

const SaveButton = styled.div`
  height: 40px;
  width: 100%;
  background-color: #00b7c2;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 15px;
  border-radius: 0.25rem;
  line-height: 2.7;
  align-items: center;
  display: flex;
  flex-direction: column;
}
`;

const OutlineButton = styled.button`
  height: 42px;
  background-color: #FFFFFF;
  color: #00b7c2;
  border-color: #00b7c2;
  border-width: 4px;
  font-weight: 600;
  font-size: 14px;
  border-radius: 0.25rem;
  line-height: 1.5;
  -webkit-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
`;

const tickOptions = {
    loop: false,
    autoplay: true,
    animationData: Tick.default,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const crossOptions = {
    loop: false,
    autoplay: true,
    animationData: Cross.default,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const heading = {
    fontSize: '20px',
    color: '#525f7f'
};

const PlugFilter = ({
    location,
    buttonModalVisible,
    linkBlockModalVisible,
    radioButtonModalVisible,
    filterListModalVisible,
    filterItemModalVisible,
    highlightFilterModalVisible
}) => {
    const header = `${'Configure '}${location.state.plug_field_name}${' Filter'}`;
    const filterWrapper = `${'webplug-filter-'}${location.state.plug_id}`;
    const embed = `
    <input 
      type='hidden'
      class='webplug-list-item'
      value='{{wf {"path":"slug","type":"PlainText"} }}'
    />
  `;
    const filterActive = 'webplug-filter-active';
    const defaultDomain = `${'https://'}${location.state.site}${'.webflow.io/'}`;
    const [selectedElement, setSelectedElement] = useState(1);
    const [domains, setDomains] = useState([]);
    const [selectedDomain, setSelectedDomain] = useState(defaultDomain);
    const [selectedDomainPage, setSelectedDomainPage] = useState('');
    const [inputFocusDisable, disableInputFocus] = useState(true);
    const [listCheck, setListCheck] = useState(-1);
    const [itemCheck, setItemCheck] = useState(-1);
    const [tickMotionStopped, setTickMotion] = useState(true);

    const setSelectedDomainPageAction = (e) => {
        setSelectedDomainPage(e.target.value);
    };

    const saveElementAction = (e) => {
        saveElement(location.state.plug_id, e);
        setSelectedElement(e);
    };

    const testConfig = async () => {
        const verifiedCollection = await verifyField(
            selectedDomain + selectedDomainPage,
            location.state.plug_id,
            localStorage.getItem('token'));

        setListCheck(verifiedCollection.data.verification.list);
        setItemCheck(verifiedCollection.data.verification.item);
        setTickMotion(false);
    };

    useEffect(() => {
        const getDomains = async () => {
            const verified = await verifyScript(
                location.state.site_id,
                localStorage.getItem('token'));
            await setDomains(verified.data.domains);
        };
        setSelectedElement(location.state.plug_element);
        getDomains();
    }, []);

    const selectBefore = (
        <Select
            value={selectedDomain}
            defaultValue={selectedDomain}
            onChange={(e) => setSelectedDomain(e.target.value)}
            className='select-before'
        >
            {domains.map((value, i) => {
                return (
                    <Option
                        key={i}
                        value={value.domain}
                    >{value.domain}</Option>
                );
            })}
        </Select>
    );

    return (
        <div>
            <h1 style={{fontSize: '22px'}}>{header}</h1>
            <hr style={{color: '#ececec', width: '100%'}}/>

            <Row style={{marginTop: '50px'}}>
                <Col span={1}>
                    <h1 style={heading}>1.</h1>
                </Col>
                <Col span={21}>
                    <h1 style={heading}>
                        Select element to filter
                    </h1>

                    <Select
                        defaultValue={location.state.plug_element}
                        style={{fontSize: '16px', width: '100%'}}
                        onChange={(e) => saveElementAction(e)}
                    >
                        <Option value={1}>Button</Option>
                        <Option value={2}>Link Block</Option>
                        <Option value={3}>Radio Button</Option>
                    </Select>
                </Col>
            </Row>

            <Row style={{marginTop: '50px'}}>
                <Col span={1}>
                    <h1 style={heading}>2.</h1>
                </Col>
                <Col span={21}>
                    <h1 style={heading}>
                        Create filter
                    </h1>
                    <h4 style={{fontSize: '16px', color: '#525f7f'}}>
                        <CreateButton
                            location={location}
                            selectedElement={selectedElement}
                            buttonModalVisible={buttonModalVisible}
                            linkBlockModalVisible={linkBlockModalVisible}
                            radioButtonModalVisible={radioButtonModalVisible}
                        />
                    </h4>
                </Col>
            </Row>

            <Row style={{marginTop: '50px'}}>
                <Col span={1}>
                    <h1 style={heading}>3.</h1>
                </Col>
                <Col span={21}>
                    <h1 style={heading}>
                        Add WebPlug Filter Class
                    </h1>
                    <h4 style={{color: '#525f7f'}}>
                        Add WebPlug filter wrapper class in filter's selector section
                    </h4>

                    <Card
                        style={{
                            borderRadius: '2px',
                            width: '100%',
                            marginTop: '4%',
                            borderColor: '#ccc',
                            backgroundColor: '#f7f7f7'
                        }}
                    >
                        <Row>
                            <Col
                                span={23}
                                style={{fontSize: '14px', fontWeight: '500'}}
                            >
                                {filterWrapper}
                            </Col>
                            <Col span={1}>

                                <CopyToClipboard text={filterWrapper}>
                                    <Button
                                        type='primary'
                                        icon={<CopyOutlined/>}
                                        size={'small'}
                                    />
                                </CopyToClipboard>
                            </Col>
                        </Row>
                    </Card>
                    <br/>
                    <OutlineButton
                        type='primary'
                        onClick={() => filterListModalVisible(true)}
                    >
                        <PlayCircleOutlined style={{fontSize: '18px'}}/>
                        {'  Add Filter Class'}
                    </OutlineButton>
                </Col>

                <Col span={2}>
                    <ListCheckComponent
                        listCheck={listCheck}
                        tickMotionStopped={tickMotionStopped}
                    />
                </Col>
            </Row>

            <Row style={{marginTop: '50px'}}>
                <Col span={1}>
                    <h1 style={heading}>4.</h1>
                </Col>
                <Col span={21}>
                    <h1 style={heading}>
                        Add Embed in {location.state.plug_field_name} Collection Item
                    </h1>
                    <h4 style={{color: '#525f7f'}}>
                        Add Embed element in {location.state.plug_field_name}
                        collection Item created in previous step
                    </h4>

                    <Card
                        style={{
                            borderRadius: '2px',
                            width: '100%',
                            marginTop: '4%',
                            borderColor: '#ccc',
                            backgroundColor: '#f7f7f7'
                        }}
                    >
                        <Row>
                            <Col
                                span={23}
                                style={{fontSize: '14px', fontWeight: '500'}}
                            >
                                {embed}
                            </Col>
                            <Col span={1}>

                                <CopyToClipboard text={embed}>
                                    <Button
                                        type='primary'
                                        icon={<CopyOutlined/>}
                                        size={'small'}
                                    />
                                </CopyToClipboard>
                            </Col>
                        </Row>
                    </Card>

                    <br/>
                    NOTE: Ensure that there is no other Embed Element in
                    <a style={{color: '#00b7c2'}}>{' '}{filterWrapper}{' '}</a>class otherwise it won't work

                    <br/>
                    <OutlineButton
                        type='primary'
                        onClick={() => filterItemModalVisible(true)}
                    >
                        <PlayCircleOutlined style={{fontSize: '18px'}}/>
                        {'  Add Embed To Filter Item'}
                    </OutlineButton>
                </Col>

                <Col span={2}>
                    <ListCheckComponent
                        listCheck={itemCheck}
                        tickMotionStopped={tickMotionStopped}
                    />
                </Col>

            </Row>

            <TestHighlight
                filterActive={filterActive}
                highlightFilterModalVisible={highlightFilterModalVisible}
                itemCheck={itemCheck}
                tickMotionStopped={tickMotionStopped}
                domains={domains}
                selectBefore={selectBefore}
                testConfig={testConfig}
                inputFocusDisable={inputFocusDisable}
                setSelectedDomainPageAction={setSelectedDomainPageAction}
                disableInputFocus={disableInputFocus}
                selectedElement={selectedElement}
            />
        </div>
    );
};

const TestHighlight = ({
    selectedElement,
    filterActive,
    highlightFilterModalVisible,
    itemCheck,
    tickMotionStopped,
    domains,
    selectBefore,
    testConfig,
    inputFocusDisable,
    setSelectedDomainPageAction,
    disableInputFocus
}) => {
    if (selectedElement === 1 || selectedElement === 2) {
        return (
            <>
                <Highlight
                    point={5}
                    filterActive={filterActive}
                    highlightFilterModalVisible={highlightFilterModalVisible}
                    itemCheck={itemCheck}
                    tickMotionStopped={tickMotionStopped}
                />
                <Test
                    point={6}
                    domains={domains}
                    selectBefore={selectBefore}
                    testConfig={testConfig}
                    inputFocusDisable={inputFocusDisable}
                    setSelectedDomainPageAction={setSelectedDomainPageAction}
                    disableInputFocus={disableInputFocus}
                />
            </>
        );
    } else {
        return (
            <Test
                point={5}
                domains={domains}
                selectBefore={selectBefore}
                testConfig={testConfig}
                inputFocusDisable={inputFocusDisable}
                setSelectedDomainPageAction={setSelectedDomainPageAction}
                disableInputFocus={disableInputFocus}
            />
        );
    }
};

const InputDomain = ({
    domains,
    selectBefore,
    selectedDomainPage,
    setSelectedDomainPageAction
}) => {
    if (domains.length > 0) {
        return (
            <Input
                addonBefore={selectBefore}
                value={selectedDomainPage}
                defaultValue={selectedDomainPage}
                onChange={setSelectedDomainPageAction}
            />
        );
    } else {
        return <div/>;
    }
};

const Test = ({
    domains,
    selectBefore,
    testConfig,
    point,
    setSelectedDomainPageAction
}) => {
    return (
        <Row style={{marginTop: '50px'}}>
            <Col span={1}>
                <h1 style={heading}>{point}.</h1>
            </Col>
            <Col span={21}>
                <h1 style={heading}>
                    Test Configuration
                </h1>
                <h4 style={{color: '#525f7f'}}>
                    You can add specific page to the URL
                    in case that's where the feature is added
                </h4>
                <div style={{marginTop: '20px'}}>
                    <InputDomain
                        domains={domains}
                        selectBefore={selectBefore}
                        setSelectedDomainPageAction={setSelectedDomainPageAction}
                    />
                </div>

                <div style={{marginTop: '40px'}}>
                    <a>
                        <SaveButton onClick={() => testConfig()}>
                            TEST
                        </SaveButton>
                    </a>
                </div>

            </Col>
        </Row>
    );
};

const Highlight = ({
    filterActive,
    highlightFilterModalVisible,
    point
}) => {
    return (
        <Row style={{marginTop: '50px'}}>
            <Col span={1}>
                <h1 style={heading}>{point}.</h1>
            </Col>
            <Col span={21}>
                <h1 style={heading}>
                    Highlight Selcted Filter
                </h1>
                <h4 style={{color: '#525f7f'}}>
                    Add class to show activated filter
                </h4>

                <Card
                    style={{
                        borderRadius: '2px',
                        width: '100%',
                        marginTop: '4%',
                        borderColor: '#ccc',
                        backgroundColor: '#f7f7f7'
                    }}
                >
                    <Row>
                        <Col
                            span={23}
                            style={{fontSize: '14px', fontWeight: '500'}}
                        >
                            {filterActive}
                        </Col>
                        <Col span={1}>

                            <CopyToClipboard text={filterActive}>
                                <Button
                                    type='primary'
                                    icon={<CopyOutlined/>}
                                    size={'small'}
                                />
                            </CopyToClipboard>
                        </Col>
                    </Row>
                </Card>
                <br/>
                <OutlineButton
                    type='primary'
                    onClick={() => highlightFilterModalVisible(true)}
                >
                    <PlayCircleOutlined style={{fontSize: '18px'}}/>
                    {'  Higlight Activated Filter Item'}
                </OutlineButton>
            </Col>

        </Row>
    );
};

const CreateButton = ({
    location,
    selectedElement,
    buttonModalVisible,
    linkBlockModalVisible,
    radioButtonModalVisible
}) => {
    if (selectedElement === 1) {
        return (
            <>
                <ol type='a'>
                    <li>Insert a CMS Collections List to the page</li>
                    <li>Connect it to <a style={{color: '#00b7c2'}}>{location.state.plug_field_name}</a> collection</li>
                    <li>Select a Button element and drop it on the {location.state.plug_field_name} list</li>
                </ol>
                <br/>
                <OutlineButton
                    type='primary'
                    onClick={() => buttonModalVisible(true)}
                >
                    <PlayCircleOutlined style={{fontSize: '18px'}}/>
                    {'  Create Button Element'}
                </OutlineButton>
            </>
        );
    } else if (selectedElement === 2) {
        return (
            <>
                <ol type='a'>
                    <li>Insert a CMS Collections List to the page</li>
                    <li>Connect it to <a style={{color: '#00b7c2'}}>{location.state.plug_field_name}</a> collection</li>
                    <li>Select a Link Block element and drop it on the {location.state.plug_field_name} list</li>
                    <li>Select a Text Block element and drop it on the Link Block</li>
                </ol>
                <br/>
                <OutlineButton
                    type='primary'
                    onClick={() => linkBlockModalVisible(true)}
                >
                    <PlayCircleOutlined style={{fontSize: '18px'}}/>
                    {'  Create Link Block Element'}
                </OutlineButton>
            </>
        );
    } else if (selectedElement === 3) {
        return (
            <>
                <ol type='a'>
                    <li>Insert Form Block element into page</li>
                    <li>Insert a CMS Collections List into Form Block element</li>
                    <li>Connect it to <a style={{color: '#00b7c2'}}>{location.state.plug_field_name}</a> collection</li>
                    <li>Select a Radio Button element and drop it on the {location.state.plug_field_name} list</li>
                </ol>
                <br/>
                <OutlineButton
                    type='primary'
                    onClick={() => radioButtonModalVisible(true)}
                >
                    <PlayCircleOutlined style={{fontSize: '18px'}}/>
                    {'  Create Radio Button Element'}
                </OutlineButton>
            </>
        );
    }
};

const ListCheckComponent = ({listCheck, tickMotionStopped}) => {
    if (listCheck === 1) {
        return (
            <Lottie
                options={tickOptions}
                isPaused={tickMotionStopped}
                height={60}
                width={60}
            />
        );
    } else if (listCheck === 0) {
        return (
            <Lottie
                options={crossOptions}
                isPaused={tickMotionStopped}
                height={60}
                width={60}
            />
        );
    } else {
        return <div/>;
    }
};

export default PlugFilter;