import React, {useEffect, useState} from 'react';
import {Row, Col, Card, Button, Input, Select} from 'antd';
import {verifyScript, verifyCollection} from '../../Api/Api';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {
    CopyOutlined,
    PlayCircleOutlined
} from '@ant-design/icons';
import styled from 'styled-components';
import Lottie from 'react-lottie';
import * as Tick from './tick.json';
import * as Cross from './cross.json';

const {Option} = Select;

const SaveButton = styled.div`
  height: 40px;
  width: 100%;
  background-color: #00b7c2;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 15px;
  border-radius: 0.25rem;
  line-height: 2.7;
  align-items: center;
  display: flex;
  flex-direction: column;
}
`;

const OutlineButton = styled.button`
  height: 42px;
  background-color: #FFFFFF;
  color: #00b7c2;
  border-color: #00b7c2;
  border-width: 4px;
  font-weight: 600;
  font-size: 14px;
  border-radius: 0.25rem;
  line-height: 1.5;
  -webkit-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
`;

const tickOptions = {
    loop: false,
    autoplay: true,
    animationData: Tick.default,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const crossOptions = {
    loop: false,
    autoplay: true,
    animationData: Cross.default,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const heading = {
    fontSize: '20px',
    color: '#525f7f'
};

const PlugCollection = ({location, collectionListModalVisible, collectionItemModalVisible}) => {
    const header = `${'Configure '}${location.state.plug_collection_name}${' collection to get filtered'}`;
    const design = `${'https://webflow.com/design/'}${location.state.site}`;
    const listWrapper = `${'webplug-list-wrapper-'}${location.state.plug_id}`;
    const embed = `
    <input 
      type='hidden'
      class='webplug-list-item'
      value='{{wf {"path":"slug","type":"PlainText"} }}'
    />
  `;
    const defaultDomain = `${'https://'}${location.state.site}${'.webflow.io/'}`;
    const [domains, setDomains] = useState([]);
    const [selectedDomain, setSelectedDomain] = useState(defaultDomain);
    const [selectedDomainPage, setSelectedDomainPage] = useState('');
    const [listCheck, setListCheck] = useState(-1);
    const [itemCheck, setItemCheck] = useState(-1);
    const [tickMotionStopped, setTickMotion] = useState(true);

    const setSelectedDomainPageAction = (e) => {
        setSelectedDomainPage(e.target.value);
    };

    const testConfig = async () => {
        const verifiedCollection = await verifyCollection(
            selectedDomain + selectedDomainPage,
            location.state.plug_id,
            localStorage.getItem('token'));
        setListCheck(verifiedCollection.data.verification.list);
        setItemCheck(verifiedCollection.data.verification.item);
        setTickMotion(false);
    };

    useEffect(() => {
        const getDomains = async () => {
            const verified = await verifyScript(
                location.state.site_id,
                localStorage.getItem('token'));
            await setDomains(verified.data.domains);
        };
        getDomains();
    }, []);

    const selectBefore = (
        <Select
            value={selectedDomain}
            defaultValue={selectedDomain}
            onChange={(e) => setSelectedDomain(e.target.value)}
            className='select-before'
        >
            {domains.map((value, i) => {
                return (
                    <Option
                        key={i}
                        value={value.domain}
                    >
                        {value.domain}
                    </Option>
                );
            })}
        </Select>
    );

    return (
        <div>
            <h1 style={{fontSize: '22px'}}>{header}</h1>
            <hr style={{color: '#ececec', width: '100%'}}/>

            <Row style={{marginTop: '50px'}}>
                <Col span={1}>
                    <h1 style={heading}>1.</h1>
                </Col>
                <Col span={21}>
                    <h1 style={heading}>
                        Add Webplug Collection List Wrapper
                    </h1>
                    <h4 style={{fontSize: '16px', color: '#525f7f'}}>
                        <ol type='a'>
                            <li>Go to
                                <a
                                    style={{color: '#00b7c2'}}
                                    onClick={() => window.open(design)}
                                >{' '}Design</a> section</li>
                            <li>
                                Select Collection List Wrapper
                                of {location.state.plug_collection_name}
                            </li>
                            <li>
                                Add the class below in its selector section
                            </li>
                        </ol>
                    </h4>

                    <Card
                        style={{
                            borderRadius: '2px',
                            width: '100%',
                            marginTop: '4%',
                            borderColor: '#ccc',
                            backgroundColor: '#f7f7f7'
                        }}
                    >
                        <Row>
                            <Col
                                span={23}
                                style={{fontSize: '14px', fontWeight: '500'}}
                            >
                                {listWrapper}
                            </Col>
                            <Col span={1}>

                                <CopyToClipboard text={listWrapper}>
                                    <Button
                                        type='primary'
                                        icon={<CopyOutlined/>}
                                        size={'small'}
                                    />
                                </CopyToClipboard>
                            </Col>
                        </Row>
                    </Card>
                    <br/>
                    <OutlineButton
                        type='primary'
                        onClick={() => collectionListModalVisible(true)}
                    >
                        <PlayCircleOutlined style={{fontSize: '18px'}}/>
                        {'  Add Collection List Wrapper'}
                    </OutlineButton>
                </Col>
                <Col span={2}>
                    <ListCheckComponent
                        listCheck={listCheck}
                        tickMotionStopped={tickMotionStopped}
                    />
                </Col>

            </Row>

            <Row style={{marginTop: '50px'}}>
                <Col span={1}>
                    <h1 style={heading}>2.</h1>
                </Col>

                <Col span={21}>
                    <h1 style={heading}>
                        Add Webplug Embed To Collection Item
                    </h1>
                    <h4 style={{fontSize: '16px', color: '#525f7f'}}>
                        <ol type='a'>
                            <li>
                                Add Embed element in Collection Item of
                                <a style={{color: '#00b7c2'}}>{' '}{listWrapper}</a>
                            </li>
                            <li>
                                Open Embed in code editor
                            </li>
                            <li>
                                Paste the code from below in it
                            </li>
                        </ol>
                    </h4>

                    <Card
                        style={{
                            borderRadius: '2px',
                            width: '100%',
                            marginTop: '4%',
                            borderColor: '#ccc',
                            backgroundColor: '#f7f7f7'
                        }}
                    >
                        <Row>
                            <Col
                                span={23}
                                style={{fontSize: '14px', fontWeight: '500'}}
                            >
                                {embed}
                            </Col>
                            <Col span={1}>

                                <CopyToClipboard text={embed}>
                                    <Button
                                        type='primary'
                                        icon={<CopyOutlined/>}
                                        size={'small'}
                                    />
                                </CopyToClipboard>
                            </Col>
                        </Row>
                    </Card>

                    <br/>
                    NOTE: Ensure that there is no other Embed Element in
                    <a style={{color: '#00b7c2'}}>{' '}{listWrapper}{' '}</a>otherwise it won't work

                    <br/>
                    <OutlineButton
                        type='primary'
                        onClick={() => collectionItemModalVisible(true)}
                    >
                        <PlayCircleOutlined style={{fontSize: '18px'}}/>
                        {'  Add Embed to Collection Item'}
                    </OutlineButton>
                </Col>

                <Col span={2}>
                    <ListCheckComponent
                        listCheck={itemCheck}
                        tickMotionStopped={tickMotionStopped}
                    />
                </Col>
            </Row>

            <Row style={{marginTop: '50px'}}>
                <Col span={1}>
                    <h1 style={heading}>3.</h1>
                </Col>
                <Col span={21}>
                    <h1 style={heading}>
                        Test Configuration
                    </h1>
                    <h4 style={{fontSize: '16px', color: '#525f7f'}}>
                        You can add specific page to the URL
                        in case that's where the feature is added
                    </h4>
                    <div style={{marginTop: '20px'}}>
                        <InputDomain
                            domains={domains}
                            selectBefore={selectBefore}
                            setSelectedDomainPageAction={setSelectedDomainPageAction}
                        />
                    </div>

                    <div style={{marginTop: '40px'}}>
                        <a>
                            <SaveButton onClick={() => testConfig()}>
                                TEST
                            </SaveButton>
                        </a>
                    </div>

                </Col>
            </Row>

        </div>
    );
};

const InputDomain = ({
    domains,
    selectBefore,
    selectedDomainPage,
    setSelectedDomainPageAction
}) => {
    if (domains.length > 0) {
        return (
            <Input
                addonBefore={selectBefore}
                value={selectedDomainPage}
                defaultValue={selectedDomainPage}
                onChange={setSelectedDomainPageAction}
            />
        );
    } else {
        return <div/>;
    }
};

const ListCheckComponent = ({listCheck, tickMotionStopped}) => {
    if (listCheck === 1) {
        return (
            <Lottie
                options={tickOptions}
                isPaused={tickMotionStopped}
                height={60}
                width={60}
            />
        );
    } else if (listCheck === 0) {
        return (
            <Lottie
                options={crossOptions}
                isPaused={tickMotionStopped}
                height={60}
                width={60}
            />
        );
    } else {
        return <div/>;
    }
};

export default PlugCollection;