import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Row, Col, Card, Spin} from 'antd';
import Dialog from 'react-modal';
import {
    getSite,
    addPlug,
    getPlugs,
    removePlug,
    saveAccessToken,
    saveStaging,
    saveLive,
    getUser,
    getPlans,
    sync
} from '../../Api/Api';
import {Link, useHistory} from 'react-router-dom';
import AddProject from './AddProject';
import HeaderComponent from './HeaderComponent';
import PlugModal from './PlugModal';
import AddPlugButton from './AddPlugButton';
import PaymentModal from './PaymentModal';
import PricingModal from './PricingModal';

const Button = styled.button`
  height: 42px;
  width: 120px;
  background-color: #00b7c2;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 14px;
  border-radius: 0.25rem;
  line-height: 1.5;
`;

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled(FlexCol)`
  padding: 3rem;
  align-items: center;
  justify-content: space-between;
`;

const pricingStyles = {
    content: {
        height: '400px',
        width: '800px',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '0.25rem',
        boxShadow: '0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)'
    }
};

const ModalContainer = styled(FlexCol)`
  padding: 0rem;
  align-items: center;
  justify-content: space-between;
`;

const StatingTag = styled.div`
  height: 22px;
  width: 80px;
  background-color: #f67280;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 12px;
  border-radius: 0.25rem;
  line-height: 1.9;
`;

const FilterType = styled.div`
  height: 22px;
  width: 140px;
  background-color: #00b7c2;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 12px;
  border-radius: 0.25rem;
  line-height: 1.9;
`;

const LiveTag = styled.div`
  height: 22px;
  width: 100px;
  background-color: #00b7c2;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 12px;
  border-radius: 0.25rem;
  line-height: 1.9;
`;

const EditButton = styled.div`
  height: 22px;
  width: 80px;
  background-color: #6078ff;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 12px;
  border-radius: 0.25rem;
  line-height: 1.9;
  align-items: center;
`;

const head = {
    fontWeight: '500',
    fontSize: '28px',
    color: '#32325D'
};

const FilterName = {
    fontWeight: '500',
    fontSize: '20px',
    color: '#32325D'
};

function Home() {
    const history = useHistory();

    const [sites, setSites] = useState([]);
    const [siteExists, setSiteExists] = useState(1);
    const [site_id, setSelectedSiteId] = useState(0);
    const [plugModal, setPlugModal] = useState(false);
    const [plugs, setPlugs] = useState([]);
    const [plugType, setPlugType] = useState(0);
    const [paymentModal, paymentModalVisible] = useState(false);
    const [pricingModal, pricingModalVisible] = useState(false);
    const [invoiceModal, invoiceModalVisible] = useState(false);
    const [user, setUser] = useState({});
    const [plans, setPlans] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [charge, setCharge] = useState('');
    const [billingDetails, setBillingDetails] = useState({
        name: '',
        address: {
            line1: '',
            city: '',
            country: 'US'
        }
    });
    const [invoiceURL, setInvoiceURL] = useState('');
    const [loading, setLoading] = useState(false);

    const logout = () => {
        localStorage.clear();
        history.push('/');
    };

    const account = () => {
        history.push('/account');
    };

    useEffect(() => {
        if (localStorage.getItem('token') === null) {
            history.push('/');
        }

        async function setConfig() {
            const token = localStorage.getItem('token');
            let siteData = {};
            try {
                siteData = await getSite(token);
            } catch (error) {
                logout();
            }

            const userData = await getUser();
            setUser(userData.data);

            setSiteExists(siteData.data.site_exists);
            setSites(siteData.data.websites);

            // Plugs only fetches the type of plugs available to add
            const plugData = await getPlugs();
            setPlugs(plugData.data.plugs);
        }

        async function setCode() {
            const userData = await getUser();
            setUser(userData.data);

            const code = await window.location.search.split('?code=').filter((s) => s !== '')[0];
            const token = localStorage.getItem('token');
            const siteData = await saveAccessToken(code, token);
            setSiteExists(siteData.data.site_exists);
            setSites(siteData.data.websites);

            // Plugs only fetches the type of plugs available to add
            const plugData = await getPlugs();
            setPlugs(plugData.data.plugs);
        }

        if (window.location.search.length === 0) {
            setConfig();
        } else {
            setCode();
        }
    }, []);

    const addPlugAction = async (plug_id, site_id, product_id) => {
        await addPlug(plug_id, site_id, product_id);
        await setPlugModal(false);
        const token = localStorage.getItem('token');
        const siteData = await getSite(token);
        await setSites(siteData.data.websites);
    };

    const removePlugAction = async (plug_id) => {
        await removePlug(plug_id);
        const token = localStorage.getItem('token');
        const siteData = await getSite(token);
        await setSites(siteData.data.websites);
    };

    const saveStagingAction = async (plug_id) => {
        const token = localStorage.getItem('token');
        const siteData = await saveStaging(plug_id, token);
        setSites(siteData.data.websites);
    };

    const saveLiveAction = async (plug_id, product_id, plug_type) => {
        const token = localStorage.getItem('token');
        const siteData = await saveLive(plug_id, token);
        setSites(siteData.data.websites);
        if (typeof (siteData.data.subscribed) !== 'undefined') {
            const plansData = await getPlans(product_id);
            setPlugType(plug_type);
            setPlans(plansData.data.plans);
            pricingModalVisible(true);
        }
    };

    const syncAction = async (plug_id, plug_product_id, plug_type) => {
        setLoading(true);
        const token = localStorage.getItem('token');
        const syncingData = await sync(plug_id, token, false);
        setLoading(false);
        if (typeof (syncingData.data.subscribed) !== 'undefined') {
            const plansData = await getPlans(plug_product_id);
            setPlugType(plug_type);
            setPlans(plansData.data.plans);
            pricingModalVisible(true);
        }
    };

    const selectPlan = (e) => {
        setSelectedPlan(e.target.value);
        const findPlan = plans.find((value) => value.id === e.target.value);
        setCharge('$' + findPlan.charge);
    };

    const buyPlanAction = () => {
        if (selectedPlan !== null) {
            pricingModalVisible(false);
            paymentModalVisible(true);
        }
    };

    if (sites.length > 0 && siteExists === 1) {
        return (
            <div className='App'>
                <InvoiceModal
                    invoiceModal={invoiceModal}
                    invoiceModalVisible={invoiceModalVisible}
                    invoiceURL={invoiceURL}
                />
                <PaymentModal
                    paymentModal={paymentModal}
                    paymentModalVisible={paymentModalVisible}
                    selectedPlan={selectedPlan}
                    user={user}
                    billingDetails={billingDetails}
                    setBillingDetails={setBillingDetails}
                    setInvoiceURL={setInvoiceURL}
                    invoiceModalVisible={invoiceModalVisible}
                    plugType={plugType}
                />
                <PricingModal
                    pricingModal={pricingModal}
                    pricingModalVisible={pricingModalVisible}
                    plans={plans}
                    selectPlan={selectPlan}
                    selectedPlan={selectedPlan}
                    buyPlanAction={buyPlanAction}
                    charge={charge}
                />
                <HeaderComponent
                    logout={logout}
                    account={account}
                />
                <Container>

                    <PlugModal
                        plugs={plugs}
                        plugModal={plugModal}
                        setPlugModal={setPlugModal}
                        addPlugAction={addPlugAction}
                        site_id={site_id}
                    />

                    {sites.map((value, i) => {
                        return (
                            <div
                                key={i}
                                style={{width: '80%', marginTop: '35px'}}
                                className='App-card'
                            >
                                <Row style={{paddingTop: '4%', paddingBottom: '4%'}}>
                                    <Col span={12}>
                                        <h4 style={head}>{value.site}</h4>
                                        <img
                                            src={value.preview}
                                            height='250px'
                                            alt={'preview'}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <a>
                                            <AddPlugButton
                                                setSelectedSiteId={setSelectedSiteId}
                                                setPlugModal={setPlugModal}
                                                value={value}
                                            />
                                        </a>
                                        {value.plugs.map((plug, j) => {
                                            return (
                                                <Card
                                                    key={j}
                                                    className='Plug-card'
                                                    style={{
                                                        borderRadius: '2px',
                                                        width: '420px',
                                                        marginTop: '4%',
                                                        borderColor: '#ccc'
                                                    }}
                                                >

                                                    <Row>
                                                        <Col span={12}>
                                                            <h5 style={FilterName}>{plug.name}
                                                                <FilterType>{plug.plug_name}</FilterType>
                                                            </h5></Col>
                                                        <Col span={4}/>
                                                        <Col span={4}>
                                                            <a>
                                                                <StagingSwitch
                                                                    staging={plug.plug_staging}
                                                                    plug_id={plug.id}
                                                                    plug_type={plug.plug_type}
                                                                    saveStagingAction={saveStagingAction}
                                                                />
                                                            </a>
                                                            <div style={{marginTop: '8px'}}>
                                                                <a>
                                                                    <LiveSwitch
                                                                        live={plug.plug_live}
                                                                        plug_id={plug.id}
                                                                        plug_product_id={plug.plug_product_id}
                                                                        plug_type={plug.plug_type}
                                                                        saveLiveAction={saveLiveAction}
                                                                        loading={loading}
                                                                        syncAction={syncAction}
                                                                    />
                                                                </a>
                                                            </div>
                                                        </Col>
                                                        <Col span={2}/>
                                                    </Row>

                                                    <Row>
                                                        <Col span={3}>
                                                            <Link
                                                                to={{
                                                                    pathname: '/plug' + plug.plug_type,
                                                                    state: {
                                                                        site_id: value.id,
                                                                        site: value.site,
                                                                        plug_id: plug.id,
                                                                        plug: plug.name,
                                                                        plug_name: plug.plug_name,
                                                                        plug_element: plug.plug_element,
                                                                        plug_collection_id: plug.plug_collection_id,
                                                                        plug_collection_name: plug.plug_collection_name,
                                                                        plug_field_id: plug.plug_field_id,
                                                                        plug_field_name: plug.plug_field_name,
                                                                        plug_multiple: plug.plug_multiple,
                                                                        plug_any_all: plug.plug_any_all,
                                                                        plug_api_key: plug.plug_api_key,
                                                                        plug_base_key: plug.plug_base_key,
                                                                        plug_table_name: plug.plug_table_name
                                                                    }
                                                                }}
                                                            >
                                                                <EditButton>SETUP</EditButton>
                                                            </Link>
                                                        </Col>
                                                        <Col span={3}/>
                                                        <Col span={3}>
                                                            <a>
                                                                <StatingTag
                                                                    onClick={() => removePlugAction(plug.id, value.id)}
                                                                >
                                                                    DELETE
                                                                </StatingTag>
                                                            </a>
                                                        </Col>
                                                        <Col span={15}/>
                                                    </Row>

                                                </Card>
                                            );
                                        })}
                                    </Col>
                                </Row>
                            </div>
                        );
                    })}
                    <AddProject/>
                </Container>
            </div>
        );
    } else if (sites.length === 0 && siteExists === 0) {
        return (
            <div className='App'>
                <HeaderComponent
                    logout={logout}
                    account={account}
                />
                <Container>
                    <a><AddProject/></a>
                </Container>
            </div>
        );
    } else {
        return (
            <div className='App'>
                <HeaderComponent
                    logout={logout}
                    account={account}
                />
                <Container>
                    <Spin size='large'/>
                </Container>
            </div>
        );
    }
}

const StagingSwitch = ({staging, plug_id, saveStagingAction, plug_type}) => {
    if (plug_type === 1) {
        if (staging === 1) {
            return (
                <>
                    <LiveTag onClick={() => saveStagingAction(plug_id)} >
                        NOW STAGING
                    </LiveTag>
                </>
            );
        } else {
            return (
                <>
                    <LiveTag
                        onClick={() => saveStagingAction(plug_id)}
                        style={{backgroundColor: '#bbbbbb'}}
                    >
                        STAGING
                    </LiveTag>
                </>
            );
        }
    } else {
        return <div/>;
    }
};

const LiveSwitch = ({live, plug_id, saveLiveAction, plug_product_id, plug_type, loading, syncAction}) => {
    if (plug_type === 1) {
        if (live === 1) {
            return (
                <LiveTag onClick={() => saveLiveAction(plug_id, plug_product_id, plug_type)} >
                    NOW LIVE
                </LiveTag>
            );
        } else {
            return (
                <LiveTag
                    onClick={() => saveLiveAction(plug_id, plug_product_id, plug_type)}
                    style={{backgroundColor: '#bbbbbb'}}
                >
                    LIVE
                </LiveTag>
            );
        }
    } else if (plug_type === 2) {
        return (
            <Spinner
                loading={loading}
                plug_id={plug_id}
                syncAction={syncAction}
                plug_product_id={plug_product_id}
                plug_type={plug_type}
            />
        );
    }
};

const InvoiceModal = ({invoiceModal, invoiceModalVisible, invoiceURL}) => {
    return (
        <Dialog
            ariaHideApp={false}
            isOpen={invoiceModal}
            style={pricingStyles}
            onRequestClose={() => invoiceModalVisible(false)}
        >
            <ModalContainer>
                <h4 style={head}>{'Proceed To Invoice'}</h4>
                <Button
                    onClick={() => {
                        window.open(invoiceURL);
                        invoiceModalVisible(false);
                    }}
                >
                    <b>Invoice</b>
                </Button>
            </ModalContainer>
        </Dialog>
    );
};

const Spinner = ({loading, plug_id, syncAction, plug_product_id, plug_type}) => {
    if (loading === true) {
        return (
            <LiveTag>
                SYNCING
            </LiveTag>
        );
    } else {
        return (
            <LiveTag
                onClick={() => syncAction(plug_id, plug_product_id, plug_type)}
            >
                {'SYNC'}
            </LiveTag>
        );
    }
};

export default Home;