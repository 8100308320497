import React, {useState, useEffect} from 'react';
import {Row, Col, Input} from 'antd';
import styled from 'styled-components';
import 'react-datasheet/lib/react-datasheet.css';
import {saveAirtable, sync} from '../../Api/Api';
import Lottie from 'react-lottie';
import * as Tick from './tick.json';
import * as Cross from './cross.json';
import * as animationData from './loading.json';

const heading = {
    fontSize: '20px',
    color: '#525f7f'
};

const SaveButton = styled.div`
  height: 40px;
  width: 100%;
  background-color: #00b7c2;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 15px;
  border-radius: 0.25rem;
  line-height: 2.7;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const tickOptions = {
    loop: false,
    autoplay: true,
    animationData: Tick.default,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const crossOptions = {
    loop: false,
    autoplay: true,
    animationData: Cross.default,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const SyncData = ({location}) => {
    const [api_key, setApiKey] = useState();
    const [base_key, setBaseKey] = useState();
    const [table_name, setTableName] = useState();
    const [tickMotionStopped, setTickMotion] = useState(true);
    const [syncMotionStopped, setSyncMotion] = useState(true);
    const [loading, setLoading] = useState(false);
    const [saved, setSaved] = useState(-1);
    const [synced, setSynced] = useState(-1);

    const save = async () => {
        const token = localStorage.getItem('token');
        const savedData = await saveAirtable(
            location.state.plug_id,
            token,
            api_key,
            base_key,
            table_name);
        setSaved(savedData.data.save);
        setTickMotion(false);
    };

    const syncAction = async (publish) => {
        setLoading(true);
        const token = localStorage.getItem('token');
        const SyncDataRes = await sync(location.state.plug_id, token, publish);
        setSynced(SyncDataRes.data.sync);
        setLoading(false);
        setSyncMotion(false);
    };

    useEffect(() => {
        setApiKey(location.state.plug_api_key);
        setBaseKey(location.state.plug_base_key);
        setTableName(location.state.plug_table_name);
    }, []);

    return (
        <div>
            <Row>
                <Col span={21}>
                    <h1 style={{fontSize: '22px'}}>Sync Data</h1>
                </Col>
            </Row>
            <hr style={{color: '#ececec', width: '100%'}}/>
            <Row style={{marginTop: '50px'}}>
                <Col span={1}>
                    <h1 style={heading}>1.</h1>
                </Col>
                <Col span={21}>
                    <h1 style={heading}>
                        Fill Airtable API Details
                    </h1>
                    <h4 style={{fontSize: '16px', color: '#525f7f'}}>
                        Get Airtable API Details from
                        <a
                            onClick={() => window.open('https://airtable.com/api')}
                            style={{color: '#18B0B9'}}
                        >
                            {' here'}
                        </a>
                    </h4>
                </Col>
                <Col span={2}>
                    <ListCheckComponent
                        listCheck={saved}
                        tickMotionStopped={tickMotionStopped}
                    />
                </Col>
            </Row>

            <Row style={{marginTop: '50px'}}>
                <Col span={1}/>
                <Col span={21}>
                    <Row>
                        <Col span={10}>
                            API Key
                        </Col>
                        <Col span={14}>
                            <Input
                                placeholder='API Key'
                                value={api_key}
                                onChange={(e) => setApiKey(e.target.value)}
                            />
                        </Col>
                    </Row>
                    <div style={{marginTop: '10px'}}>
                        <Row>
                            <Col span={10}>
                                Base Key
                            </Col>
                            <Col span={14}>
                                <Input
                                    placeholder='Base Key'
                                    value={base_key}
                                    onChange={(e) => setBaseKey(e.target.value)}
                                />
                            </Col>
                        </Row>
                    </div>
                    <div style={{marginTop: '10px'}}>
                        <Row>
                            <Col span={10}>
                                Table Name
                            </Col>
                            <Col span={14}>
                                <Input
                                    placeholder='Table Name'
                                    value={table_name}
                                    onChange={(e) => setTableName(e.target.value)}
                                />
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>

            <Row style={{marginTop: '50px'}}>
                <Col span={1}/>
                <Col span={21}>
                    <a>
                        <SaveButton onClick={save}>
                            {'VERIFY AIRTABLE SETTINGS'}
                        </SaveButton>
                    </a>
                </Col>
            </Row>

            <Row style={{marginTop: '50px'}}>
                <Col span={1}/>
                <Col span={10}>
                    <Spinner
                        loading={loading}
                        defaultOptions={defaultOptions}
                        syncAction={() => syncAction(false)}
                        buttonText={'SYNC'}
                    />
                </Col>
                <Col span={1}/>
                <Col span={10}>
                    <Spinner
                        loading={loading}
                        defaultOptions={defaultOptions}
                        syncAction={() => syncAction(true)}
                        buttonText={'SYNC & PUBLISH'}
                    />
                </Col>
                <Col span={2}>
                    <ListCheckComponent
                        listCheck={synced}
                        tickMotionStopped={syncMotionStopped}
                    />
                </Col>
            </Row>
        </div>
    );
};

const ListCheckComponent = ({listCheck, tickMotionStopped}) => {
    if (listCheck === 1) {
        return (
            <Lottie
                options={tickOptions}
                isPaused={tickMotionStopped}
                height={60}
                width={60}
            />
        );
    } else if (listCheck === 0) {
        return (
            <Lottie
                options={crossOptions}
                isPaused={tickMotionStopped}
                height={60}
                width={60}
            />
        );
    } else {
        return <div/>;
    }
};

const Spinner = ({loading, defaultOptions, syncAction, buttonText}) => {
    if (loading === true) {
        return (
            <SaveButton>
                <Lottie
                    options={defaultOptions}
                    height={30}
                    width={175}
                />
            </SaveButton>
        );
    } else {
        return (
            <a>
                <SaveButton
                    onClick={syncAction}
                >
                    {buttonText}
                </SaveButton>
            </a>
        );
    }
};

export default SyncData;