import React, {useEffect, useState} from 'react';
import {Layout} from 'antd';
import styled from 'styled-components';
import Dialog from 'react-modal';
import {useHistory} from 'react-router-dom';
import {getCollections, getFields} from '../../Api/Api';
import HeaderComponent from './HeaderComponent';
import PlugSidebar from './PlugSidebar';
import PlugSettings from './PlugSettings';
import PlugScript from './PlugScript';
import PlugCollection from './PlugCollection';
import PlugFilter from './PlugFilter';
import PlugExtra from './PlugExtra';
import Vimeo from '@u-wave/react-vimeo';

const {Content} = Layout;

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled(FlexCol)`
  padding: 3rem 7rem 3rem 3rem;
  align-items: left;
  justify-content: space-between;
`;

const customStyles = {
    content: {
        height: '500px',
        width: '900px',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '0.25rem',
        boxShadow: '0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)'
    }
};

const head = {
    fontWeight: '500',
    fontSize: '28px',
    color: '#32325D'
};

const ModalContainer = styled(FlexCol)`
  padding: 0rem;
  align-items: center;
  justify-content: space-between;
`;

function PlugHome({location}) {
    const history = useHistory();

    const [collections, setCollections] = useState([]);
    const [fields, setFields] = useState([]);
    const [fieldSelector, setFieldSelector] = useState(true);
    const [selectedCollection, setSelectedCollection] = useState('');
    const [selectedField, setSelectedField] = useState('');
    const [selectedMultiple, setSelectedMultiple] = useState(1);
    const [selectedAnyAll, setSelectedAnyAll] = useState(0);
    const [firstMount, setFirstMount] = useState(true);
    const [selectedKeys, setSelectedKeys] = useState(['1']);
    const [plugScriptModal, plugScriptModalVisible] = useState(false);
    const [collectionListModal, collectionListModalVisible] = useState(false);
    const [collectionItemModal, collectionItemModalVisible] = useState(false);
    const [buttonModal, buttonModalVisible] = useState(false);
    const [linkBlockModal, linkBlockModalVisible] = useState(false);
    const [radioButtonModal, radioButtonModalVisible] = useState(false);
    const [filterListModal, filterListModalVisible] = useState(false);
    const [filterItemModal, filterItemModalVisible] = useState(false);
    const [filterSelectionModal, filterSelectionModalVisible] = useState(false);
    const [highlightFilterModal, highlightFilterModalVisible] = useState(false);

    useEffect(() => {
        setSelectedMultiple(location.state.plug_multiple);
        setSelectedAnyAll(location.state.plug_any_all);

        async function setConfig() {
            if (firstMount === true) {
                const collectionData = await getCollections(location.state.site_id);
                setCollections(collectionData.data.collection);

                if (location.state.plug_collection_id !== null &&
                    location.state.plug_collection_id !== undefined) {
                    if (location.state.plug_collection_id.length > 0) {
                        setSelectedCollection(location.state.plug_collection_id);
                        const fieldData = await getFields(location.state.plug_collection_id);
                        await setFields(fieldData.data.field);
                    }

                    if (location.state.plug_field_id.length > 0) {
                        setSelectedField(location.state.plug_field_id);
                        setFieldSelector(false);
                    }
                }
            }
        }

        setConfig();
        setFirstMount(false);

        if (fields.length > 0) {
            setFieldSelector(false);
        } else {
            setFieldSelector(true);
        }

    /*
    const code = window.location.search.split('?code=').filter(s => s !== '')[0];
    // Test const code = '32423424322424'
    axios.post('https://webplug-backend.onrender.com/accesstoken/', {code});
    */
    }, [fields]);

    const selectCollection = async (value) => {
        setSelectedCollection(value);
        const fieldData = await getFields(value);
        await setFields(fieldData.data.field);
        if (fieldData.data.field.length === 0) {
            setSelectedField('');
        }
    };

    const selectField = async (value) => {
        setSelectedField(value);
    };

    const selectMultiple = (value) => {
        setSelectedMultiple(value);
    };

    const selectAnyAll = (value) => {
        setSelectedAnyAll(value);
    };

    const logout = () => {
        localStorage.clear();
        history.push('/');
    };

    return (
        <div className='App'>
            <FilterSelectionModal
                filterSelectionModal={filterSelectionModal}
                filterSelectionModalVisible={filterSelectionModalVisible}
            />
            <FilterItemModal
                filterItemModal={filterItemModal}
                filterItemModalVisible={filterItemModalVisible}
            />
            <FilterListModal
                filterListModal={filterListModal}
                filterListModalVisible={filterListModalVisible}
            />
            <RadioButtonModal
                radioButtonModal={radioButtonModal}
                radioButtonModalVisible={radioButtonModalVisible}
            />
            <LinkBlockModal
                linkBlockModal={linkBlockModal}
                linkBlockModalVisible={linkBlockModalVisible}
            />
            <ButtonModal
                buttonModal={buttonModal}
                buttonModalVisible={buttonModalVisible}
            />
            <PlugScriptModal
                plugScriptModal={plugScriptModal}
                plugScriptModalVisible={plugScriptModalVisible}
            />
            <CollectionListModal
                collectionListModal={collectionListModal}
                collectionListModalVisible={collectionListModalVisible}
            />
            <CollectionItemModal
                collectionItemModal={collectionItemModal}
                collectionItemModalVisible={collectionItemModalVisible}
            />
            <HighlightFilterModal
                highlightFilterModal={highlightFilterModal}
                highlightFilterModalVisible={highlightFilterModalVisible}
            />
            <HeaderComponent logout={logout}/>
            <Layout style={{backgroundColor: '#FFFFFF', paddingTop: '10vh'}}>

                <PlugSidebar
                    selectedKeys={selectedKeys}
                    location={location}
                    setSelectedKeys={setSelectedKeys}
                />

                <Layout style={{height: '90vh', backgroundColor: '#f4f9f4'}}>
                    <Content className='site-layout-background'>
                        <div
                            style={{marginLeft: '15%', marginTop: '5%', width: '70%', marginBottom: '5vh'}}
                            className='App-form'
                        >
                            <Container >
                                <div style={{marginLeft: '8%'}}>
                                    <PlugPage
                                        selectedKeys={selectedKeys}
                                        setSelectedKeys={setSelectedKeys}
                                        selectedCollection={selectedCollection}
                                        selectCollection={selectCollection}
                                        collections={collections}
                                        selectedField={selectedField}
                                        selectMultiple={selectMultiple}
                                        selectedMultiple={selectedMultiple}
                                        selectedAnyAll={selectedAnyAll}
                                        selectAnyAll={selectAnyAll}
                                        selectField={selectField}
                                        fields={fields}
                                        fieldSelector={fieldSelector}
                                        location={location}
                                        plugScriptModalVisible={plugScriptModalVisible}
                                        collectionListModalVisible={collectionListModalVisible}
                                        collectionItemModalVisible={collectionItemModalVisible}
                                        buttonModalVisible={buttonModalVisible}
                                        linkBlockModalVisible={linkBlockModalVisible}
                                        radioButtonModalVisible={radioButtonModalVisible}
                                        filterListModalVisible={filterListModalVisible}
                                        filterItemModalVisible={filterItemModalVisible}
                                        highlightFilterModalVisible={highlightFilterModalVisible}
                                        filterSelectionModalVisible={filterSelectionModalVisible}
                                    />
                                </div>
                            </Container>
                        </div>
                    </Content>
                </Layout>
            </Layout>
        </div>
    );
}

const PlugPage = ({
    selectedKeys,
    setSelectedKeys,
    selectedCollection,
    selectCollection,
    collections,
    selectedField,
    fieldSelector,
    selectField,
    selectMultiple,
    selectedMultiple,
    selectedAnyAll,
    selectAnyAll,
    fields,
    location,
    plugScriptModalVisible,
    collectionListModalVisible,
    collectionItemModalVisible,
    buttonModalVisible,
    linkBlockModalVisible,
    radioButtonModalVisible,
    filterListModalVisible,
    filterItemModalVisible,
    highlightFilterModalVisible,
    filterSelectionModalVisible
}) => {
    if (selectedKeys[0] === '1') {
        return (
            <PlugSettings
                selectedCollection={selectedCollection}
                selectCollection={selectCollection}
                collections={collections}
                selectedField={selectedField}
                selectField={selectField}
                selectMultiple={selectMultiple}
                selectedMultiple={selectedMultiple}
                selectedAnyAll={selectedAnyAll}
                selectAnyAll={selectAnyAll}
                fields={fields}
                fieldSelector={fieldSelector}
                location={location}
            />
        );
    } else if (selectedKeys[0] === '2') {
        return (
            <PlugScript
                location={location}
                setSelectedKeys={setSelectedKeys}
                plugScriptModalVisible={plugScriptModalVisible}
            />
        );
    } else if (selectedKeys[0] === '3') {
        return (
            <PlugCollection
                location={location}
                collectionListModalVisible={collectionListModalVisible}
                collectionItemModalVisible={collectionItemModalVisible}
            />
        );
    } else if (selectedKeys[0] === '4') {
        return (
            <PlugFilter
                location={location}
                buttonModalVisible={buttonModalVisible}
                linkBlockModalVisible={linkBlockModalVisible}
                radioButtonModalVisible={radioButtonModalVisible}
                filterListModalVisible={filterListModalVisible}
                filterItemModalVisible={filterItemModalVisible}
                highlightFilterModalVisible={highlightFilterModalVisible}
            />
        );
    } else if (selectedKeys[0] === '5') {
        return (
            <PlugExtra
                location={location}
                filterSelectionModalVisible={filterSelectionModalVisible}
            />
        );
    }
};

const PlugScriptModal = ({plugScriptModal, plugScriptModalVisible}) => {
    return (
        <Dialog
            ariaHideApp={false}
            isOpen={plugScriptModal}
            style={customStyles}
            onRequestClose={() => plugScriptModalVisible(false)}
        >
            <ModalContainer>
                <h4 style={head}>{'Add WebPlug To Script'}</h4>
                <Vimeo
                    video='414436126'
                    height='400px'
                    width='800px'
                />
            </ModalContainer>
        </Dialog>
    );
};

const CollectionListModal = ({collectionListModal, collectionListModalVisible}) => {
    return (
        <Dialog
            ariaHideApp={false}
            isOpen={collectionListModal}
            style={customStyles}
            onRequestClose={() => collectionListModalVisible(false)}
        >
            <ModalContainer>
                <h4 style={head}>{'Add Class to Collection List'}</h4>
                <Vimeo
                    video='414710877'
                    height='400px'
                    width='800px'
                />
            </ModalContainer>
        </Dialog>
    );
};

const CollectionItemModal = ({collectionItemModal, collectionItemModalVisible}) => {
    return (
        <Dialog
            ariaHideApp={false}
            isOpen={collectionItemModal}
            style={customStyles}
            onRequestClose={() => collectionItemModalVisible(false)}
        >
            <ModalContainer>
                <h4 style={head}>{'Add Embed to Collection Item'}</h4>
                <Vimeo
                    video='414722554'
                    height='400px'
                    width='800px'
                />
            </ModalContainer>
        </Dialog>
    );
};

const ButtonModal = ({buttonModal, buttonModalVisible}) => {
    return (
        <Dialog
            ariaHideApp={false}
            isOpen={buttonModal}
            style={customStyles}
            onRequestClose={() => buttonModalVisible(false)}
        >
            <ModalContainer>
                <h4 style={head}>{'Create Button Element'}</h4>
                <Vimeo
                    video='414742599'
                    height='400px'
                    width='800px'
                />
            </ModalContainer>
        </Dialog>
    );
};

const LinkBlockModal = ({linkBlockModal, linkBlockModalVisible}) => {
    return (
        <Dialog
            ariaHideApp={false}
            isOpen={linkBlockModal}
            style={customStyles}
            onRequestClose={() => linkBlockModalVisible(false)}
        >
            <ModalContainer>
                <h4 style={head}>{'Create Link Block Element'}</h4>
                <Vimeo
                    video='414821754'
                    height='400px'
                    width='800px'
                />
            </ModalContainer>
        </Dialog>
    );
};

const RadioButtonModal = ({radioButtonModal, radioButtonModalVisible}) => {
    return (
        <Dialog
            ariaHideApp={false}
            isOpen={radioButtonModal}
            style={customStyles}
            onRequestClose={() => radioButtonModalVisible(false)}
        >
            <ModalContainer>
                <h4 style={head}>{'Create Radio Button Element'}</h4>
                <Vimeo
                    video='415068976'
                    height='400px'
                    width='800px'
                />
            </ModalContainer>
        </Dialog>
    );
};

const FilterListModal = ({filterListModal, filterListModalVisible}) => {
    return (
        <Dialog
            ariaHideApp={false}
            isOpen={filterListModal}
            style={customStyles}
            onRequestClose={() => filterListModalVisible(false)}
        >
            <ModalContainer>
                <h4 style={head}>{' Add Filter Class'}</h4>
                <Vimeo
                    video='415543211'
                    height='400px'
                    width='800px'
                />
            </ModalContainer>
        </Dialog>
    );
};

const FilterItemModal = ({filterItemModal, filterItemModalVisible}) => {
    return (
        <Dialog
            ariaHideApp={false}
            isOpen={filterItemModal}
            style={customStyles}
            onRequestClose={() => filterItemModalVisible(false)}
        >
            <ModalContainer>
                <h4 style={head}>{' Add Embed To Filter Item'}</h4>
                <Vimeo
                    video='415564515'
                    height='400px'
                    width='800px'
                />
            </ModalContainer>
        </Dialog>
    );
};

const HighlightFilterModal = ({highlightFilterModal, highlightFilterModalVisible}) => {
    return (
        <Dialog
            ariaHideApp={false}
            isOpen={highlightFilterModal}
            style={customStyles}
            onRequestClose={() => highlightFilterModalVisible(false)}
        >
            <ModalContainer>
                <h4 style={head}>{' Highlight selected Filter'}</h4>
                <Vimeo
                    video='417973367'
                    height='400px'
                    width='800px'
                />
            </ModalContainer>
        </Dialog>
    );
};

const FilterSelectionModal = ({filterSelectionModal, filterSelectionModalVisible}) => {
    return (
        <Dialog
            ariaHideApp={false}
            isOpen={filterSelectionModal}
            style={customStyles}
            onRequestClose={() => filterSelectionModalVisible(false)}
        >
            <ModalContainer>
                <h4 style={head}>{' Add filter-selections Div'}</h4>
                <Vimeo
                    video='430334779'
                    height='400px'
                    width='800px'
                />
            </ModalContainer>
        </Dialog>
    );
};

export default PlugHome;