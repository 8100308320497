import React, {useState} from 'react';
import {Row, Col, Card, Button} from 'antd';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {
    CopyOutlined,
    PlayCircleOutlined
} from '@ant-design/icons';
import styled from 'styled-components';
import {verifyScript} from '../../Api/Api';
import Lottie from 'react-lottie';
import * as animationData from './tick.json';

const SaveButton = styled.div`
  height: 40px;
  width: 100%;
  background-color: #00b7c2;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 15px;
  border-radius: 0.25rem;
  line-height: 2.7;
  align-items: center;
  display: flex;
  flex-direction: column;
}
`;

const OutlineButton = styled.button`
  height: 42px;
  background-color: #FFFFFF;
  color: #00b7c2;
  border-color: #00b7c2;
  border-width: 4px;
  font-weight: 600;
  font-size: 14px;
  border-radius: 0.25rem;
  line-height: 1.5;
  -webkit-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
`;

const heading = {
    fontSize: '20px',
    color: '#525f7f'
};

const PlugScript = ({location, setSelectedKeys, plugScriptModalVisible}) => {
    const [tickMotionStopped, setTickMotion] = useState(true);
    const [domains, setDomains] = useState([]);

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: animationData.default,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const siteLink = `${'https://webflow.com/dashboard/sites/'}${location.state.site}${'/code'}`;
    const script = `
    <script id="webplug-script" type="text/javascript" src="https://www.webplug.xyz/webplug.js"></script>
    <script type="text/javascript">
        webplug("${location.state.site_id}");
    </script>
  `;

    const verifyScriptAction = async () => {
        const verified = await verifyScript(
            location.state.site_id,
            localStorage.getItem('token'));
        setDomains(verified.data.domains);
        if (verified.data.domains.length > 0) {
            setTickMotion(false);
        }
    };

    const next = () => setSelectedKeys(['3']);

    return (
        <div>
            <h1 style={{fontSize: '22px'}}>Add Webplug script to your site</h1>
            <hr style={{color: '#ececec', width: '100%'}}/>

            <Row style={{marginTop: '50px'}}>
                <Col span={1}>
                    <h1 style={heading}>1.</h1>
                </Col>
                <Col span={23}>
                    <h1 style={heading}>
                        Open custom code section
                    </h1>
                    <h4 style={{color: '#525f7f'}}>
                        <a
                            onClick={() => window.open(siteLink)}
                            style={{fontSize: '17px', color: '#18B0B9'}}
                        >
                            {siteLink}
                        </a>
                    </h4>
                </Col>
            </Row>

            <Row style={{marginTop: '50px'}}>
                <Col span={1}>
                    <h1 style={heading}>2.</h1>
                </Col>
                <Col span={21}>
                    <h1 style={heading}>
                        Add webplug script
                    </h1>
                    <h4 style={{fontSize: '16px', color: '#525f7f'}}>
                        Copy and paste the script in head code
                    </h4>

                    <Card
                        style={{
                            borderRadius: '2px',
                            width: '100%',
                            marginTop: '4%',
                            borderColor: '#ccc',
                            backgroundColor: '#f7f7f7'
                        }}
                    >
                        <Row>
                            <Col
                                span={21}
                                style={{fontSize: '14px', fontWeight: '500'}}
                            >{script}</Col>
                            <Col span={2}/>
                            <Col span={1}>
                                <CopyToClipboard text={script}>
                                    <Button
                                        type='primary'
                                        icon={<CopyOutlined/>}
                                        size={'small'}
                                    />
                                </CopyToClipboard>
                            </Col>
                        </Row>
                    </Card>
                    <br/>
                    <OutlineButton
                        type='primary'
                        onClick={() => plugScriptModalVisible(true)}
                    >
                        <PlayCircleOutlined style={{fontSize: '18px'}}/>
                        {'  Add WebPlug Script'}
                    </OutlineButton>
                </Col>
            </Row>

            <Row style={{marginTop: '50px'}}>
                <Col span={1}>
                    <h1 style={heading}>3.</h1>
                </Col>
                <Col span={23}>
                    <h1 style={heading}>
                        Publish the changes
                    </h1>
                    <h4 style={{fontSize: '16px', color: '#525f7f'}}>
                        Click on publish to Selected Domains
                    </h4>
                    <h4 style={{color: '#525f7f'}}>
                        <img
                            style={{
                                width: '40%',
                                borderImageWidth: '2px',
                                borderColor: '#00000'
                            }}
                            src={require('./publish.png')}
                        />
                    </h4>
                </Col>
            </Row>

            <Row style={{marginTop: '50px'}}>
                <Col span={1}/>
                <Col span={21}>
                    <a>
                        <SaveButton onClick={verifyScriptAction}>
                            TEST
                        </SaveButton>
                    </a>
                </Col>
            </Row>
            <Domains
                next={next}
                domains={domains}
                setTickMotion={setTickMotion}
                defaultOptions={defaultOptions}
                tickMotionStopped={tickMotionStopped}
                setSelectedKeys={setSelectedKeys}
            />

        </div>
    );
};

const CheckTick = ({script, defaultOptions, tickMotionStopped}) => {
    if (script === 1) {
        return (
            <Lottie
                options={defaultOptions}
                isPaused={tickMotionStopped}
                height={60}
                width={60}
            />
        );
    } else {
        return <div/>;
    }
};

const Domains = ({
    domains,
    defaultOptions,
    tickMotionStopped,
    next
}) => {
    if (domains.length > 0) {
        return (
            <>
                <Row style={{marginTop: '50px'}}>
                    <Col span={1}>
                        <h1 style={heading}>3.</h1>
                    </Col>
                    <Col span={23}>
                        <h1 style={heading}>
                            Verify script
                        </h1>
                        <h4 style={{color: '#525f7f'}}>
                            Verifying if script has been added to site
                        </h4>
                    </Col>
                </Row>

                {domains.map((value, i) => {
                    return (
                        <Row
                            key={i}
                            style={{marginTop: '10px'}}
                        >
                            <Col span={1}/>
                            <Col
                                span={20}
                                style={{marginTop: '20px'}}
                            >
                                <h3 style={{color: '#525f7f'}}>{value.domain}</h3>
                            </Col>
                            <Col span={3}>
                                <CheckTick
                                    script={value.script}
                                    defaultOptions={defaultOptions}
                                    tickMotionStopped={tickMotionStopped}
                                />
                            </Col>
                        </Row>
                    );
                })}
                <Row style={{marginTop: '50px'}}>
                    <Col span={1}/>
                    <Col span={21}>
                        <a>
                            <SaveButton onClick={next}>
                                NEXT
                            </SaveButton>
                        </a>
                    </Col>
                </Row>
            </>
        );
    } else {
        return <div/>;
    }
};

export default PlugScript;