import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {PageHeader, Col, Row, Layout, Card} from 'antd';
import {Link} from 'react-router-dom';
import ReactGA from 'react-ga';
import {LiteYouTubeEmbed} from 'react-lite-youtube-embed';

ReactGA.initialize('UA-31455093-9');
ReactGA.pageview(window.location.pathname + window.location.search);

const {Header} = Layout;

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled(FlexCol)`
  padding: 1rem;
  align-items: center;
  padding: 1rem;
  justify-content: space-between;
`;

const Button = styled.button`
  width: 100px;
  height: 42px;
  background-color: #00b7c2;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 14px;
  border-radius: 0.25rem;
  line-height: 1.5;
  border-color: #00b7c2;
`;

const highlight = {
    background: '#b4f2e1',
    color: '#000000'
};

const heading = {
    fontSize: '20px',
    color: '#525f7f'
};

const price = {
    fontSize: '100px',
    color: '#525f7f'
};

function Home() {
    const [screenWidth, setScreenWidth] = useState('70%');

    useEffect(() => {
        if (window.innerWidth < 480) {
            setScreenWidth('100%');
        }
    }, []);

    return (
        <div
            className='App'
            style={{backgroundColor: '#FFFFFF'}}
        >
            <Header style={{height: 10, background: '#00b7c2'}}/>
            <PageHeader
                style={{backgroundColor: '#f4f9f4'}}
                className='site-page-header'
                title={
                    <>
                        <div id='parent_div_1'>
                            <Icon/>
                        </div>
                        <div id='parent_div_2'>
                            WebPlug
                        </div>
                    </>
                }
                extra={[
                    <Link
                        key='2'
                        to={'/login'}
                    ><Button >Login</Button></Link>,
                    <Link
                        key='1'
                        to={'/register'}
                    ><Button>Join</Button></Link>
                ]}
            />
            <Row style={{backgroundColor: '#f4f9f4'}}>
                <Col span={24}>
                    <Container>
                        <h1 style={{textAlign: 'center', fontSize: '45px', lineHeight: '1.5', fontWeight: '600'}}>
                            Dynamic Fiter and Airtable CMS for your Webflow site
                        </h1>
                    </Container>
                </Col>
            </Row>

            <div style={{textAlign: 'center'}}>
                <Container>
                    <picture>
                        <source
                            style={{
                                width: '70%',
                                borderImageWidth: '2px',
                                borderColor: '#00000'
                            }}
                            type='image/avif'
                            srcSet={'https://firebasestorage.googleapis.com/v0/b/squarespace-chat.appspot.com/o/images%2Fwebflow-dynamic-filter.avif?alt=media&token=1497bfb4-7b5c-42f5-9d36-d307a669b761'}
                        />
                        <img
                            style={{
                                width: '70%',
                                borderImageWidth: '2px',
                                borderColor: '#00000'
                            }}
                            alt='webflow-dynamic-filter'
                            src={'https://firebasestorage.googleapis.com/v0/b/squarespace-chat.appspot.com/o/images%2Fwebflow-dynamic-filter.jpg?alt=media&token=636030a5-a1a4-4a97-9a92-519a8fbca48e'}
                        />
                    </picture>
                </Container>

                <Container style={{margin: '0 auto', width: screenWidth}}>
                    <h2 style={{fontWeight: '600', fontSize: '35px', textAlign: 'center', lineHeight: '1.3', marginTop: '0px'}}>
                        <b style={highlight}>Dynamic Filter for Webflow CMS</b> using reference field
                    </h2>
                </Container>

                <Container>
                    <picture>
                        <source
                            style={{
                                width: '70%',
                                borderImageWidth: '2px',
                                borderColor: '#00000'
                            }}
                            type='image/avif'
                            srcSet={'https://firebasestorage.googleapis.com/v0/b/squarespace-chat.appspot.com/o/images%2Fwebflow-airtable-sync.avif?alt=media&token=c61d4293-2a34-4005-9dac-d317bc07a74f'}
                        />
                        <img
                            style={{
                                width: '70%',
                                borderImageWidth: '2px',
                                borderColor: '#00000'
                            }}
                            alt='webflow-airtable-sync'
                            src={'https://firebasestorage.googleapis.com/v0/b/squarespace-chat.appspot.com/o/images%2Fwebflow-airtable-sync.jpg?alt=media&token=67e730bd-5fa3-497e-a247-7d23c196128c'}
                        />
                    </picture>
                </Container>

                <Card style={{margin: '0 auto', width: '50%'}}>
                    <LiteYouTubeEmbed
                        id='5HvFRTUcuQo'
                        title='Sync Webflow CMS with Airtable'
                        style={{width: '100%', height: 290}}
                        allowFullScreen={true}
                    />
                </Card>

                <Container style={{margin: '0 auto', width: screenWidth}}>
                    <h2 style={{fontWeight: '600', fontSize: '35px', textAlign: 'center', lineHeight: '1.3', marginTop: '0px'}}>
                        <b style={highlight}>Sync Webflow CMS With Airtable base</b> in one click, no complex triggers like zapier
                    </h2>
                </Container>
            </div>

            <div style={{textAlign: 'center', backgroundColor: '#f4f9f4', borderRadius: '30px', width: '90%', marginLeft: '5%'}}>
                <br/>
                <h1 style={heading}>{'Monthly Subscription'}</h1>
                <div
                    className='rcards'
                >
                    <div className='rcard'>
                        <div style={{textAlign: 'center'}}>
                            <br/>
                            <h1 style={heading}>{'Dynamic Filter'}</h1>
                            <div style={{marginTop: '4%'}}>
                                <h1 style={price}>
                                    $10
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div className='rcard'>
                        <div style={{textAlign: 'center'}}>
                            <br/>
                            <h1 style={heading}>{'Airtable Connector'}</h1>
                            <div style={{marginTop: '4%'}}>
                                <h1 style={price}>
                                    $16
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Header style={{marginTop: 40, height: 10, background: '#00b7c2'}}/>
        </div>
    );
}

function Icon() {
    return (
        <div style={{width: '10px'}}>
            <svg
                xmlns='http://www.w3.org/2000/svg'
                width='30pt'
                height='30pt'
                viewBox='0 0 512 512'
            >
                <path d='M255.055 0C113.988.512 0 114.836 0 256c0 141.48 114.496 256 256 256 11.047 0 20-8.953 20-20s-8.953-20-20-20c-119.379 0-216-96.61-216-216 0-119.02 96.094-215.566 215.2-216C373.29 39.512 472 137.395 472 257.832c0 74.715-60.785 135.5-135.504 135.5h-1.832c-26.3 0-48.613-17.398-56.035-41.289 43.535-10.254 76.035-49.422 76.035-96.043v-78.668c0-11.047-8.953-20-20-20h-19.332V98.668c0-11.047-8.953-20-20-20s-20 8.953-20 20v58.664h-38.668V98.668c0-11.047-8.953-20-20-20-11.043 0-20 8.953-20 20v58.664h-19.332c-11.047 0-20 8.953-20 20V256c0 48.156 34.68 88.355 80.375 96.96 8.602 45.692 48.805 80.372 96.957 80.372h1.832c96.774 0 175.504-78.73 175.504-175.5C512 116.738 396.883-.445 255.055 0zm-57.723 256v-58.668h117.332V256c0 32.348-26.316 58.668-58.664 58.668-32.352 0-58.668-26.32-58.668-58.668zm0 0'/>
            </svg>
        </div>
    );
}

export default Home;