import React, {useEffect, useState} from 'react';
import {Layout} from 'antd';
import styled from 'styled-components';
import HeaderComponent from './HeaderComponent';
import {useHistory} from 'react-router-dom';
import {getCollections} from '../../Api/Api';
import GenerateTemplate from './GenerateTemplate';
import SyncData from './SyncData';
import PlugAirtableSidebar from './PlugAirtableSidebar';

const {Content} = Layout;

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled(FlexCol)`
  padding: 3rem 7rem 3rem 3rem;
  align-items: left;
  justify-content: space-between;
`;

const PlugAirtableHome = ({
    location
}) => {
    const history = useHistory();
    const logout = () => {
        localStorage.clear();
        history.push('/');
    };
    const [collections, setCollections] = useState([]);
    const [selectedCollection, setSelectedCollection] = useState('');
    const [selectedCollectionName, setSelectedCollectionName] = useState('');
    const [selectedKeys, setSelectedKeys] = useState(['1']);

    useEffect(() => {
        async function setConfig() {
            const collectionData = await getCollections(location.state.site_id);
            setCollections(collectionData.data.collection);
        }
        setConfig();
        setSelectedCollection(location.state.plug_collection_id);
    }, []);

    const selectCollection = async (value) => {
        const selected = await collections.find((collection) => {
            if (collection.id === value) {
                return collection;
            }
        });
        setSelectedCollectionName(selected.name);
        setSelectedCollection(value);
    };

    return (
        <div className='App'>
            <HeaderComponent logout={logout}/>
            <Layout style={{backgroundColor: '#FFFFFF', paddingTop: '10vh'}}>

                <PlugAirtableSidebar
                    location={location}
                    selectedKeys={selectedKeys}
                    setSelectedKeys={setSelectedKeys}
                />

                <Layout style={{height: '90vh', backgroundColor: '#f4f9f4'}}>
                    <Content className='site-layout-background'>
                        <div
                            style={{marginLeft: '15%', marginTop: '5%', width: '70%', marginBottom: '5vh'}}
                            className='App-form'
                        >
                            <Container >
                                <div style={{marginLeft: '8%'}}>
                                    <AirtablePage
                                        collections={collections}
                                        location={location}
                                        selectedCollection={selectedCollection}
                                        selectedCollectionName={selectedCollectionName}
                                        selectCollection={selectCollection}
                                        selectedKeys={selectedKeys}
                                    />
                                </div>
                            </Container>
                        </div>
                    </Content>
                </Layout>
            </Layout>
        </div>
    );
};

const AirtablePage = ({
    collections,
    location,
    selectedCollection,
    selectedCollectionName,
    selectCollection,
    selectedKeys
}) => {
    if (selectedKeys[0] === '1') {
        return (
            <GenerateTemplate
                collections={collections}
                location={location}
                selectedCollection={selectedCollection}
                selectedCollectionName={selectedCollectionName}
                selectCollection={selectCollection}
            />
        );
    } else if (selectedKeys[0] === '2') {
        return (
            <SyncData location={location}/>
        );
    }
};

export default PlugAirtableHome;