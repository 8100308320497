import React, {useState, useEffect} from 'react';
import {Row, Col, Layout, Input, notification, Avatar} from 'antd';
import styled from 'styled-components';
import {Link, useHistory} from 'react-router-dom';
import {getUser, resetPassword, getSubscriptions, cancelSubscription} from './../Api/Api';
import {
    InfoCircleTwoTone,
    UserOutlined
} from '@ant-design/icons';
import Dialog from 'react-modal';

const {Header} = Layout;
const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled(FlexCol)`
  padding: 3rem;
  align-items: center;
  justify-content: space-between;
`;

const OutlineButton = styled.button`
  height: 42px;
  width: 100px;
  background-color: #FFFFFF;
  color: #00b7c2;
  border-color: #00b7c2;
  border-width: 4px;
  font-weight: 600;
  font-size: 14px;
  border-radius: 0.25rem;
  line-height: 1.5;
  -webkit-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
`;

const Button = styled.button`
  height: 42px;
  background-color: #00b7c2;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 14px;
  border-radius: 0.25rem;
  line-height: 1.5;
`;

const LiveTag = styled.button`
    height: 42px;
    width: 120px;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 14px;
    border-radius: 0.25rem;
    line-height: 1.5;
`;

const customStyles = {
    content: {
        height: '200px',
        width: '50%',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '0.25rem',
        boxShadow: '0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)'
    }
};

const ModalContainer = styled(FlexCol)`
  padding: 0rem;
  align-items: center;
  justify-content: space-between;
`;

const Titlehead = {
    fontWeight: '800',
    fontSize: '20px',
    color: '#32325D'
};

const head = {
    fontWeight: '500',
    fontSize: '20px',
    color: '#32325D'
};

const heading = {
    fontSize: '20px',
    color: '#525f7f'
};

function Home() {
    const history = useHistory();

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [subscriptions, setSubscriptions] = useState([]);
    const [cancelPlanModal, cancelPlanModalVisible] = useState(false);
    const [selectedSubscription, setSelectedSubscription] = useState('');

    useEffect(() => {
        async function setData() {
            const emailData = await getUser();
            setEmail(emailData.data.email);
            setName(emailData.data.name);
            const subscriptionsData = await getSubscriptions();
            setSubscriptions(subscriptionsData.data.subscriptions);
        }
        setData();
    }, []);

    const logout = () => {
        localStorage.clear();
        history.push('/');
    };

    const errorMessage = (errorMsg) => {
        notification.open({
            message:
    <div style={{fontSize: '30px'}}>
        <Row>
            <Col span={3}>
                <InfoCircleTwoTone/>
            </Col>
            <Col span={15}>
                {errorMsg}
            </Col>
        </Row>
    </div>,
            description: '',
            onClick: () => {
                notification.close();
            }
        });
    };

    const reset = async () => {
        const resetData = await resetPassword(
            currentPassword,
            newPassword,
            confirmPassword,
            localStorage.getItem('token')
        );
        errorMessage(resetData.data.message);
    };

    const cancelPlan = async () => {
        await cancelSubscription(selectedSubscription);
        const subscriptionsData = await getSubscriptions();
        setSubscriptions(subscriptionsData.data.subscriptions);
        cancelPlanModalVisible(false);
    };

    const openCancelModal = (subscription_id) => {
        setSelectedSubscription(subscription_id);
        cancelPlanModalVisible(true);
    };

    return (
        <div className='App'>
            <HeaderComponent logout={logout}/>
            <CancelPlanModal
                cancelPlanModal={cancelPlanModal}
                cancelPlanModalVisible={cancelPlanModalVisible}
                cancelPlan={cancelPlan}
            />
            <Row>
                <Col span={8}>
                    <Container>
                        <h1 style={heading}>
                            Account
                        </h1>
                        <div
                            className='App-login'
                            style={{borderRadius: 0, marginTop: '50px'}}
                        >
                            <div style={{width: '90%', marginLeft: '16px'}}>
                                <div style={{marginTop: '4%'}}>
                                    <h1 style={heading}>{'ACCOUNT DETAILS'}</h1>
                                </div>
                                <div style={{marginTop: '4%'}}>
                                    <Avatar
                                        size='large'
                                        style={{backgroundColor: '#00b7c2'}}
                                        icon={<UserOutlined/>}
                                    />
                                </div>
                                <div style={{paddingTop: '4%'}}>
                                    <h1 style={heading}>{name}</h1>
                                </div>
                                <div style={{paddingTop: '4%'}}>
                                    <h1 style={heading}>{email}</h1>
                                </div>
                            </div>
                        </div>

                    </Container>
                </Col>
                <Col span={8}>
                    <Container>
                        <h1 style={heading}>
                            Subscriptions
                        </h1>
                        {subscriptions.map((value, i) => {
                            return (
                                <div
                                    className='Sub-card'
                                    style={{borderRadius: 0, marginTop: '50px'}}
                                    key={i}
                                >
                                    <div style={{marginTop: '4%'}}>
                                        <h4 style={head}>{value.nickname}</h4>
                                    </div>
                                    <div style={{marginTop: '4%'}}>
                                        <h4 style={head}>{value.current_period_end}</h4>
                                    </div>
                                    <Button
                                        style={{width: '90%', marginBottom: '10px'}}
                                        onClick={() => openCancelModal(value.subscription_id)}
                                        type='primary'
                                    >
                                        CANCEL PLAN
                                    </Button>
                                </div>
                            );
                        })}

                    </Container>
                </Col>
                <Col span={8}>
                    <Container>
                        <h1 style={heading}>
                            Reset Password
                        </h1>
                        <div
                            className='App-login'
                            style={{borderRadius: 0, marginTop: '50px'}}
                        >
                            <div style={{width: '90%', marginLeft: '16px'}}>
                                <div style={{marginTop: '4%'}}>
                                    <h4 style={head}>RESET</h4>
                                </div>
                                <div style={{paddingTop: '4%'}}>
                                    <Input
                                        addonBefore={'Current Password'}
                                        value={currentPassword}
                                        onChange={(e) => setCurrentPassword(e.target.value)}
                                        placeholder='Password'
                                        type={'password'}
                                    />
                                </div>
                                <div style={{paddingTop: '4%'}}>
                                    <Input
                                        addonBefore={'New Password'}
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        placeholder='Password'
                                        type={'password'}
                                    />
                                </div>
                                <div style={{paddingTop: '4%'}}>
                                    <Input
                                        addonBefore={'Confirm Password'}
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        placeholder='Password'
                                        type={'password'}
                                    />
                                </div>
                                <div style={{paddingTop: '15%', paddingBottom: '5%'}}>
                                    <Button
                                        style={{width: '100%'}}
                                        onClick={reset}
                                        type='primary'
                                    >
                                        RESET
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Container>
                </Col>
            </Row>
        </div>
    );
}

const CancelPlanModal = ({cancelPlanModal, cancelPlanModalVisible, cancelPlan}) => {
    return (
        <Dialog
            ariaHideApp={false}
            isOpen={cancelPlanModal}
            style={customStyles}
            onRequestClose={() => cancelPlanModalVisible(false)}
        >
            <ModalContainer>
                <h4 style={head}>{'Are you sure you want to cancel your plan ?'}</h4>
                <Row>
                    <Col span={10}>
                        <LiveTag
                            onClick={() => cancelPlan()}
                            style={{
                                borderColor: '#f36886',
                                backgroundColor: '#f36886',
                                width: '150px'
                            }}
                        >
                            {'YES'}
                        </LiveTag>
                    </Col>
                    <Col span={4}/>
                    <Col span={10}>
                        <Button 
                            onClick={() => cancelPlanModalVisible()}
                            style={{
                                width: '150px'
                            }}
                        >
                            NO
                        </Button>
                    </Col>
                </Row>
            </ModalContainer>
        </Dialog>
    );
};

const HeaderComponent = ({logout}) => {
    return (
        <Header style={{background: '#FFFFFF'}}>
            <Row>
                <Col span={1}>
                    <Icon/>
                </Col>
                <Col span={18}>
                    <Link to={'/dashboard'}><h1 style={Titlehead}>WebPlug</h1></Link>
                </Col>
                <Col span={2}/>
                <Col
                    span={2}
                    style={{marginLeft: '10px'}}
                >
                    <OutlineButton onClick={() => logout()}>LOGOUT</OutlineButton>
                </Col>
            </Row>
        </Header>
    );
};

function Icon() {
    return (
        <div style={{marginTop: '10px', width: '10px'}}>
            <svg
                xmlns='http://www.w3.org/2000/svg'
                width='30pt'
                height='30pt'
                viewBox='0 0 512 512'
            >
                <path d='M255.055 0C113.988.512 0 114.836 0 256c0 141.48 114.496 256 256 256 11.047 0 20-8.953 20-20s-8.953-20-20-20c-119.379 0-216-96.61-216-216 0-119.02 96.094-215.566 215.2-216C373.29 39.512 472 137.395 472 257.832c0 74.715-60.785 135.5-135.504 135.5h-1.832c-26.3 0-48.613-17.398-56.035-41.289 43.535-10.254 76.035-49.422 76.035-96.043v-78.668c0-11.047-8.953-20-20-20h-19.332V98.668c0-11.047-8.953-20-20-20s-20 8.953-20 20v58.664h-38.668V98.668c0-11.047-8.953-20-20-20-11.043 0-20 8.953-20 20v58.664h-19.332c-11.047 0-20 8.953-20 20V256c0 48.156 34.68 88.355 80.375 96.96 8.602 45.692 48.805 80.372 96.957 80.372h1.832c96.774 0 175.504-78.73 175.504-175.5C512 116.738 396.883-.445 255.055 0zm-57.723 256v-58.668h117.332V256c0 32.348-26.316 58.668-58.664 58.668-32.352 0-58.668-26.32-58.668-58.668zm0 0'/>
            </svg>
        </div>
    );
}

export default Home;