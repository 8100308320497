import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Login from './Components/Login';
import Dashboard from './Components/Dashboard/Dashboard';
import PlugHome from './Components/Plug/PlugHome';
import PlugAirtableHome from './Components/Plug/PlugAirtableHome';
import Home from './Components/Home';
import Register from './Components/Register';
import Tou from './Components/Tou';
import About from './Components/About';
import Account from './Components/Account';
import Airtable from './Components/Airtable';
import Privacy from './Components/Privacy';

export default function Routes() {
    return (
        <Router>
            <Switch>
                <Route
                    exact={true}
                    path='/login'
                    component={Login}
                />
                <Route
                    exact={true}
                    path='/'
                    component={Home}
                />
                <Route
                    exact={true}
                    path='/register'
                    component={Register}
                />
                <Route
                    exact={true}
                    path='/dashboard'
                    component={Dashboard}
                />
                <Route
                    exact={true}
                    path='/plug1'
                    component={PlugHome}
                />
                <Route
                    exact={true}
                    path='/plug2'
                    component={PlugAirtableHome}
                />
                <Route
                    exact={true}
                    path='/tou'
                    component={Tou}
                />
                <Route
                    exact={true}
                    path='/privacy'
                    component={Privacy}
                />
                <Route
                    exact={true}
                    path='/about'
                    component={About}
                />
                <Route
                    exact={true}
                    path='/account'
                    component={Account}
                />
                <Route
                    exact={true}
                    path='/airtable'
                    component={Airtable}
                />
            </Switch>
        </Router>
    );
}