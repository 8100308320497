import React from 'react';
import styled from 'styled-components';
import Dialog from 'react-modal';

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;

const ModalContainer = styled(FlexCol)`
  padding: 0rem;
  align-items: center;
  justify-content: space-between;
`;

const head = {
    fontWeight: '500',
    fontSize: '28px',
    color: '#32325D'
};

const customStyles = {
    content: {
        height: '300px',
        width: '600px',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '0.25rem',
        boxShadow: '0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)'
    }
};

const Button = styled.button`
  height: 42px;
  width: 200px;
  background-color: #00b7c2;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 18px;
  border-radius: 0.25rem;
  line-height: 1.5;
`;

const PlugModal = ({plugs, plugModal, setPlugModal, addPlugAction, site_id}) => {
    return (
        <Dialog
            ariaHideApp={false}
            isOpen={plugModal}
            style={customStyles}
            onRequestClose={() => setPlugModal(false)}
        >
            <ModalContainer>
                <h4 style={head}>{'Select Plug'}</h4>
                {plugs.map((value, i) => {
                    return (
                        <div
                            key={i}
                            style={{marginTop: '10px'}}
                        >
                            <Button
                                onClick={() => addPlugAction(value.id, site_id, value.product_id)}
                            >
                                {value.name}
                            </Button>
                            <br/>
                        </div>
                    );
                })}
            </ModalContainer>
        </Dialog>
    );
};

export default PlugModal;