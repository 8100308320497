import fetch from './Config';

export const getSite = (token) => {
    const headers = {
        Authorization: 'Token ' + localStorage.getItem('token')
    };
    const body = {token};
    return fetch.post('/site/', body, {headers});
};

export const addPlug = (plug_id, site_id, product_id) => {
    const headers = {
        Authorization: 'Token ' + localStorage.getItem('token')
    };
    const token = localStorage.getItem('token');
    const body = {plug_id, site_id, token, product_id};
    return fetch.post('/addplug/', body, {headers});
};

export const getPlugs = () => {
    return fetch.get('/plugs/');
};

export const removePlug = (plug_id) => {
    const headers = {
        Authorization: 'Token ' + localStorage.getItem('token')
    };
    const body = {plug_id};
    return fetch.post('/removeplug/', body, {headers});
};

export const getTemplate = (collection_id, token, plug_id) => {
    const headers = {
        Authorization: 'Token ' + localStorage.getItem('token')
    };
    const body = {collection_id, token, plug_id};
    return fetch.post('/template/', body, {headers});
};

export const getCollections = (site_id) => {
    const headers = {
        Authorization: 'Token ' + localStorage.getItem('token')
    };
    const body = {site_id};
    return fetch.post('/collection/', body, {headers});
};

export const getFields = (collection_id) => {
    const headers = {
        Authorization: 'Token ' + localStorage.getItem('token')
    };
    const body = {collection_id};
    return fetch.post('/field/', body, {headers});
};

export const getPlans = (product_id) => {
    const headers = {
        Authorization: 'Token ' + localStorage.getItem('token')
    };
    const body = {product_id};
    return fetch.post('/plans/', body, {headers});
};

export const getUser = () => {
    const headers = {
        Authorization: 'Token ' + localStorage.getItem('token')
    };
    const body = {token: localStorage.getItem('token')};
    return fetch.post('/user/', body, {headers});
};

export const sync = (plug_id, token, publish) => {
    const headers = {
        Authorization: 'Token ' + localStorage.getItem('token')
    };
    const body = {plug_id, token, publish};
    return fetch.post('/sync/', body, {headers});
};

export const resetPassword = (
    currentPassword,
    newPassword,
    confirmPassword,
    token
) => {
    const headers = {
        Authorization: 'Token ' + localStorage.getItem('token')
    };
    const body = {
        currentPassword,
        newPassword,
        confirmPassword,
        token
    };
    return fetch.post('/resetpassword/', body, {headers});
};

export const forgotPassword = (email) => {
    const body = {email};
    return fetch.post('/forgot/', body);
};

export const createSubscription = (
    paymentMethodId,
    customerId,
    planId,
    plugType
) => {
    const headers = {
        Authorization: 'Token ' + localStorage.getItem('token')
    };
    const token = localStorage.getItem('token');
    const body = {
        paymentMethodId,
        customerId,
        planId,
        plugType,
        token
    };
    return fetch.post('/subscription/', body, {headers});
};

export const getSubscriptions = () => {
    const headers = {
        Authorization: 'Token ' + localStorage.getItem('token')
    };
    const token = localStorage.getItem('token');
    return fetch.post('/subscriptions/', {token}, {headers});
};

export const cancelSubscription = (subscription_id) => {
    const headers = {
        Authorization: 'Token ' + localStorage.getItem('token')
    };
    return fetch.post('/cancelsubscription/', {subscription_id}, {headers});
};

export const createBeta = (email) => {
    return fetch.post('/beta/', {email});
};

export const savePlug = (
    plug_id,
    collection_id,
    field_id,
    multiple,
    any_all,
    token
) => {
    const headers = {
        Authorization: 'Token ' + localStorage.getItem('token')
    };
    const body = {
        plug_id,
        collection_id,
        field_id,
        multiple,
        any_all,
        token
    };
    return fetch.post('/saveplug/', body, {headers});
};

export const saveStaging = (plug_id, token) => {
    const headers = {
        Authorization: 'Token ' + localStorage.getItem('token')
    };
    const body = {plug_id, token};
    return fetch.post('/savestaging/', body, {headers});
};

export const saveLive = (plug_id, token) => {
    const headers = {
        Authorization: 'Token ' + localStorage.getItem('token')
    };
    const body = {plug_id, token};
    return fetch.post('/savelive/', body, {headers});
};

export const saveElement = (plug_id, plug_element) => {
    const headers = {
        Authorization: 'Token ' + localStorage.getItem('token')
    };
    const body = {plug_id, plug_element};
    return fetch.post('/saveelement/', body, {headers});
};

export const saveName = (plug_id, plug_name, token) => {
    const headers = {
        Authorization: 'Token ' + localStorage.getItem('token')
    };
    const body = {plug_id, plug_name, token};
    return fetch.post('/savename/', body, {headers});
};

export const saveAirtable = (plug_id, token, api_key, base_key, table_name) => {
    const headers = {
        Authorization: 'Token ' + localStorage.getItem('token')
    };
    const body = {plug_id, token, api_key, base_key, table_name};
    return fetch.post('/airtable/', body, {headers});
};

export const register = (name, email, password) => {
    const body = {name, email, password};
    return fetch.post('/register/', body);
};

export const login = (email, password) => {
    const body = {email, password};
    return fetch.post('/login/', body);
};

export const saveAccessToken = (code, token) => {
    const headers = {
        Authorization: 'Token ' + localStorage.getItem('token')
    };
    const body = {code, token};
    return fetch.post('/accesstoken/', body, {headers});
};

export const verifyScript = (site_id, token) => {
    const headers = {
        Authorization: 'Token ' + localStorage.getItem('token')
    };
    const body = {site_id, token};
    return fetch.post('/verifyscript/', body, {headers});
};

export const verifyCollection = (url, plug_id, token) => {
    const headers = {
        Authorization: 'Token ' + localStorage.getItem('token')
    };
    const body = {url, plug_id, token};
    return fetch.post('/verifycollection/', body, {headers});
};

export const verifyField = (url, plug_id, token) => {
    const headers = {
        Authorization: 'Token ' + localStorage.getItem('token')
    };
    const body = {url, plug_id, token};
    return fetch.post('/verifyfield/', body, {headers});
};