import React from 'react';
import {Row, Col, Card, Button} from 'antd';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {
    CopyOutlined,
    PlayCircleOutlined
} from '@ant-design/icons';
import styled from 'styled-components';

const OutlineButton = styled.button`
  height: 42px;
  background-color: #FFFFFF;
  color: #00b7c2;
  border-color: #00b7c2;
  border-width: 4px;
  font-weight: 600;
  font-size: 14px;
  border-radius: 0.25rem;
  line-height: 1.5;
  -webkit-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
`;

const heading = {
    fontSize: '20px',
    color: '#525f7f'
};

const PlugExtra = ({location, filterSelectionModalVisible}) => {
    const filterSelectionsDiv = 'filter-selectors';
    const listWrapper = `${'webplug-filter-'}${location.state.plug_id}`;

    return (
        <div>
            <h1 style={{fontSize: '22px'}}>Configure Dynamic Flilter Tag</h1>
            <hr style={{color: '#ececec', width: '100%'}}/>

            <img
                style={{
                    width: '100%',
                    borderImageWidth: '2px',
                    borderColor: '#00000'
                }}
                src={require('../Images/tag.png')}
            />

            <Row style={{marginTop: '50px'}}>
                <Col span={1}>
                    <h1 style={heading}>1.</h1>
                </Col>
                <Col span={21}>
                    <h1 style={heading}>
                        Create a div called
                        <a
                            style={{color: '#00b7c2'}}
                        >{' '}{filterSelectionsDiv}</a> parallel to {' '}{listWrapper}
                    </h1>
                    <Card
                        style={{
                            borderRadius: '2px',
                            width: '100%',
                            marginTop: '4%',
                            borderColor: '#ccc',
                            backgroundColor: '#f7f7f7'
                        }}
                    >
                        <Row>
                            <Col
                                span={23}
                                style={{fontSize: '14px', fontWeight: '500'}}
                            >
                                {filterSelectionsDiv}
                            </Col>
                            <Col span={1}>

                                <CopyToClipboard text={filterSelectionsDiv}>
                                    <Button
                                        type='primary'
                                        icon={<CopyOutlined/>}
                                        size={'small'}
                                    />
                                </CopyToClipboard>
                            </Col>
                        </Row>
                    </Card>
                    <br/>
                    <OutlineButton
                        type='primary'
                        onClick={() => filterSelectionModalVisible(true)}
                    >
                        <PlayCircleOutlined style={{fontSize: '18px'}}/>
                        {'  Create Filter Selections Div'}
                    </OutlineButton>
                </Col>
            </Row>

        </div>
    );
};

export default PlugExtra;