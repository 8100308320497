import React from 'react';
import styled from 'styled-components';
import {Row, Col} from 'antd';

const Button = styled.button`
  height: 42px;
  width: 120px;
  background-color: #00b7c2;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 14px;
  border-radius: 0.25rem;
  border-color: #00b7c2;
  line-height: 1.5;
`;

const AddPlugButton = ({setSelectedSiteId, setPlugModal, value}) => {
    return (
        <Row>
            <Col span={12}>
                <Button
                    onClick={() => {
                        setSelectedSiteId(value.id);
                        setPlugModal(true);
                    }}
                >
                    {'ADD PLUG'}
                </Button>
            </Col>
            <Col span={12}/>
        </Row>
    );
};

export default AddPlugButton;