import React from 'react';
import 'antd/dist/antd.css';
import Routes from './Routes';
import './App.css';

function App() {
    return (
        <Routes/>
    );
}

export default App;
